<template>
  <SAddressBe
    v-if="props.locale === 'nl-BE' || props.locale === 'fr-BE'"
    v-bind="props"
    v-on="addressOn"
  />

  <SAddressFrFr
    v-else-if="props.locale === 'fr-FR'"
    v-bind="props"
    v-on="addressOn"
  />

  <SAddressNlNl
    v-else-if="props.locale === 'nl-NL'"
    v-bind="props"
    v-on="addressOn"
  />
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import type { LocaleIso } from "@/lib/helpers/locales";

import { size as sizeProp } from "@/lib/components/logic/atoms/input/props";
import SAddressBe from "@/lib/components/organisms/address/SAddressBe.vue";
import SAddressFrFr from "@/lib/components/organisms/address/SAddressFrFr.vue";
import SAddressNlNl from "@/lib/components/organisms/address/SAddressNlNl.vue";
import { reEmit } from "@/lib/composables/componentComposable";

const props = defineProps({
  availableLocales: {
    type: Array as PropType<readonly LocaleIso[]>,
    default: () => [],
  },
  locale: { type: String as PropType<LocaleIso>, required: true },
  size: sizeProp,
  zipcode: { type: String as PropType<string | null>, default: "" },
  houseNumber: { type: String as PropType<string | null>, default: "" },
  street: { type: String as PropType<string | null>, default: "" },
  city: { type: String as PropType<string | null>, default: "" },
});

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string): void;
  (event: "update:houseNumber", houseNumber: string): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string): void;
  (event: "update:zipcode", zipcode: string): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const addressOn = reEmit(emit, [
  "update:locale",
  "update:zipcode",
  "update:houseNumber",
  "update:street",
  "update:city",
  "focus",
  "blur",
  "validationError",
]);
</script>
