import type { PropType, Ref } from "vue";

import { omit } from "radash";
import { computed, toRef } from "vue";

import type { UseTextInputEmit } from "@/lib/components/logic/molecules/useTextInput";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { LocaleIso } from "@/lib/helpers/locales";

import * as useTextInput from "@/lib/components/logic/molecules/useTextInput";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { emailDomains } from "@/lib/enums/emailDomains";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { bestMatch } from "@/lib/helpers/stringSimilarity";
import { eager } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules";
import { useValidationProviderScoped } from "@/lib/validation/ValidationProvider/useValidationProvider";

type EmailParts = {
  domain: string;
  secondLevelDomain: string;
  tld: string;
  username: string;
};

const props = propsDefinition({
  ...omit(useTextInput.props, [
    "type",
    "rules",
    "validationEvents",
    "maxlength",
    "decimals",
    "decimalsFill",
    "min",
    "max",
    "modelValue",
  ]),
  ...useValidationProviderScoped<string | null | undefined>(),
  modelValue: {
    type: String as PropType<string | null | undefined>,
    default: null,
  },
  locale: {
    type: String as PropType<LocaleIso>,
    required: true,
    default: "nl-NL",
  },
});

const emits = emitsDefinition(useTextInput.emits);

type UseEmailProps = DefineProps<typeof props>;
type UseEmailEmit = UseTextInputEmit;

function useEmailTypoCorrection(
  email: Ref<Readonly<string | null | undefined>>,
  domains: Ref<readonly string[]>,
  emit: UseEmailEmit,
) {
  const emailParts = computed<EmailParts>(() => {
    if (typeof email.value !== "string") {
      return {
        username: "",
        domain: "",
        tld: "",
        secondLevelDomain: "",
      };
    }
    const parts = email.value.split("@");
    const domain = parts[1]?.toLowerCase() || "";
    const splitDomain = domain.split(".");
    return {
      username: parts[0]?.toLowerCase() || "",
      domain,
      tld: splitDomain[splitDomain.length - 1] || "",
      secondLevelDomain: splitDomain[0] || "",
    };
  });

  const suggestedDomain = computed(() => {
    return bestMatch(emailParts.value.domain, domains.value, {
      threshold: 0.85,
    });
  });

  const suggestion = computed(() => {
    if (
      !suggestedDomain.value ||
      suggestedDomain.value === emailParts.value.domain
    ) {
      return null;
    }
    return `${emailParts.value.username}@${suggestedDomain.value}`;
  });

  function applySuggestion() {
    emit("update:modelValue", suggestion.value);
  }

  const typoSuggestRule = defineRule({
    name: "emailTypoSuggest",
    validate: (
      _value: number | string | null | undefined,
      params: { apply: () => void; suggestion: Ref<string | null> },
    ) => !params.suggestion.value,
    events: ["blur"],
    component: "suggestion",
    color: "warning",
    blocking: false,
  })({ suggestion, apply: applySuggestion });

  return { typoSuggestRule, suggestion };
}

function use(props: UseEmailProps, emit: UseEmailEmit) {
  const localizedEmailDomains = computed(() => {
    return emailDomains[props.locale] as string[];
  });

  const { typoSuggestRule } = useEmailTypoCorrection(
    toRef(() => props.modelValue),
    localizedEmailDomains,
    emit,
  );

  return {
    textInput: {
      props: mergeReactive(props, {
        rules: [typoSuggestRule],
        validationEvents: eager,
        type: "email" as const,
        maxlength: 255,
      }),
      on: reEmit(emit, useTextInput.emits),
    },
  };
}

export type { UseEmailEmit, UseEmailProps };
export { emits, props, use };
