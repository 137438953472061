import type { PropType } from "vue";

import { faEuroSign } from "@fortawesome/pro-regular-svg-icons";
import { reactiveComputed } from "@vueuse/core";
import { omit } from "radash";
import { toRef } from "vue";

import type { UseTextInputProps } from "@/lib/components/logic/molecules/useTextInput";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import * as useTextInput from "@/lib/components/logic/molecules/useTextInput";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { faSolvariCredit } from "@/lib/icons/fontAwesome";

const props = propsDefinition({
  ...omit(useTextInput.props, ["type"]),
  modelValue: {
    type: [String, Number] as PropType<number | null | undefined>,
  },
  preset: {
    type: String as PropType<string | null>,
    default: null,
  },
});

const emits = emitsDefinition(useTextInput.emits);

type UseNumberInputPresetProps = DefineProps<typeof props>;
type UseNumberInputPresetEmit = UseValidationProviderEmits & {
  (event: "blur" | "focus", name: string): void;
  (event: "click" | "mousedown" | "mouseup"): void;
  (event: "click", pointerEvent: PointerEvent): void;
  (event: "keydown" | "keyup", keyboardEvent: KeyboardEvent): void;
  (event: "update:loading", value: UseTextInputProps["loading"]): void;
  (event: "update:modelValue", value: number | null | undefined): void;
};

function use(props: UseNumberInputPresetProps, emit: UseNumberInputPresetEmit) {
  const preset = toRef(() => props.preset);

  const presetProps = reactiveComputed(() => {
    if (preset.value === "euro") {
      return {
        decimals: 2,
        decimalsFill: true,
        prefixIcon: faEuroSign,
      };
    } else if (preset.value === "credit") {
      return {
        decimals: 2,
        decimalsFill: true,
        prefixIcon: faSolvariCredit,
      };
    }
    return {};
  });

  return {
    textInput: {
      props: mergeReactive(props, { type: "number" as const }, presetProps),
      on: reEmit(emit, useTextInput.emits),
    },
  };
}

export type { UseNumberInputPresetEmit, UseNumberInputPresetProps };
export { emits, props, use };
