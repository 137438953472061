<template>
  <div
    class="s-checkbox-radio-group"
    :class="{
      's-checkbox-radio-group--inline': inline,
      's-checkbox-radio-group--outlined': outlined,
    }"
  >
    <slot
      v-for="item in items"
      name="checkboxRadio"
      v-bind="{
        key: item.key,
        props: item.props,
        on: item.on,
      }"
    >
      <SCheckboxRadio :key="item.key" v-bind="item.props" v-on="item.on">
        <template v-for="(_index, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </SCheckboxRadio>
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { UseCheckboxRadioGroupEmit } from "@/lib/components/logic/atoms/useCheckboxRadioGroup";

import SCheckboxRadio from "@/lib/components/atoms/checkbox-radio/SCheckboxRadio.vue";
import * as useCheckboxRadioGroup from "@/lib/components/logic/atoms/useCheckboxRadioGroup";

const props = defineProps(useCheckboxRadioGroup.props);
const emit = defineEmits<UseCheckboxRadioGroupEmit>();

defineSlots<{
  checkboxRadio: (component: (typeof items.value)[0]) => never;
  // eslint-disable-next-line
  [key: string]: (data: any) => never;
}>();

const { items, inline, outlined } = useCheckboxRadioGroup.use(props, emit);
</script>

<style lang="postcss">
.s-checkbox-radio-group {
  @apply flex flex-col;

  &--inline {
    @apply flex-row flex-wrap;
  }

  &--outlined {
    @apply gap-2;
  }
}
</style>
