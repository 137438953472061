import type { LocaleIso } from "@solvari/common-fe/helpers";

import { getSiteLocale } from "@solvari/common-fe/helpers";
import { getSentry } from "@solvari/common-fe/integrations";
import { toRef } from "@vueuse/core";
import { defineStore } from "pinia";
import { watch, watchEffect } from "vue";

import type { FormRequestResource } from "@/api/forms.api";

import { fetchFormByGroup, fetchFormById } from "@/api/forms.api";
import { useI18nForms } from "@/plugins/i18n";
import { useAbTestsStore } from "@/plugins/store/abTests";
import { useApplicationStore } from "@/plugins/store/application";
import { useFeatureFlagStore } from "@/plugins/store/featureFlags";
import { useGtmStore } from "@/plugins/store/gtm";

type FormProps = {
  formGroupId: number | null;
  formId: number | null;
  formVariantName?: string;
  locale: LocaleIso;
  revisionId: number | null;
};

/*
  Stores data about the form itself
 */
export const useFormStore = defineStore("form", {
  state: () => ({
    formGetProps: {
      locale: getSiteLocale(),
      formId: null,
      revisionId: null,
      formVariantName: undefined,
      formGroupId: null,
    } as FormProps,
    formGetPending: false,
    formGetResult: null as FormRequestResource | null,
    formGetError: null as Error | null,
    crossSellFormIds: [] as number[],
  }),
  getters: {
    name: ({ formGetResult }) => formGetResult?.data.name,
    locale: ({ formGetResult }) => formGetResult?.data.locale,
    product: ({ formGetResult }) => {
      if (!formGetResult) {
        return formGetResult;
      }
      // TODO remove after BE is updated, backwards compatibility only
      if ("productId" in formGetResult.data) {
        return {
          id: formGetResult.data.productId,
          name: null,
        };
      }
      return formGetResult.data.product;
    },
    formId: ({ formGetResult }) => formGetResult?.data.formId ?? 0,
    revisionId: ({ formGetResult }) => formGetResult?.data.revisionId ?? 0,
    formGroupId: ({ formGetProps }) => formGetProps.formGroupId ?? 0,
    steps: ({ formGetResult }) => formGetResult?.data.steps ?? [],
    availableLocales: ({ formGetResult }) =>
      formGetResult?.availableLocales ?? [],
    isCrossSellForm: () => !!useApplicationStore().originLeadUuid,
    nextCrossSellFormId({
      crossSellFormIds,
      formGetProps,
    }): number | undefined {
      const currentFormIndex = crossSellFormIds.findIndex(
        (id) => id === formGetProps.formId,
      );
      return crossSellFormIds[currentFormIndex + 1];
    },
    canCrossSell() {
      return !!this.nextCrossSellFormId && useFeatureFlagStore().crossSell;
    },
  },
  actions: {
    async fetchForm(props: FormProps) {
      if (props.formGroupId) {
        return await fetchFormByGroup(
          props.formGroupId,
          props.locale,
          props.formVariantName,
        );
      }

      if (props.formId) {
        return await fetchFormById(
          props.formId,
          props.formVariantName,
          props.revisionId,
        );
      }

      return new Error("Neither a formGroupId or a formId was provided");
    },
    async fetchAndSetForm(props: FormProps) {
      this.formGetPending = true;
      this.formGetResult = null;
      this.formGetError = null;

      const result = await this.fetchForm(props);

      if (result instanceof Error) {
        this.formGetError = result;

        getSentry().captureException(result);
        void useGtmStore().formErrorEvent({
          name: "formLoading",
          label: useI18nForms().tr("loadingError.title"),
          error: useI18nForms().tr("loadingError.title"),
        });
      } else {
        this.formGetResult = result;
      }

      this.formGetPending = false;
    },
    goToCrossSellForm() {
      const nextCrossSellFormId = this.nextCrossSellFormId;
      if (!nextCrossSellFormId) {
        return;
      }
      this.formGetProps.formGroupId = null;
      this.formGetProps.revisionId = null;
      this.formGetProps.formId = nextCrossSellFormId;
    },
    initStoreWatchers(props: Readonly<FormProps>) {
      const abTestsStore = useAbTestsStore();

      // This in intentionally not reactive, it should only run on init
      this.formGetProps.locale = props.locale;
      this.formGetProps.formGroupId = abTestsStore.getFormGroupId(
        props.formGroupId,
      );
      this.formGetProps.formId = abTestsStore.getFormId(
        props.formId,
        props.formGroupId,
      );
      this.formGetProps.revisionId = props.revisionId;
      this.formGetProps.formVariantName =
        abTestsStore.getVariantName(props.formId, props.formGroupId) ??
        undefined;

      watch(
        toRef(() => this.formGetProps.locale),
        useGtmStore().localeSwitchEvent,
      );

      watchEffect(() => this.fetchAndSetForm(this.formGetProps));

      watchEffect(() => {
        if (this.locale && this.formGetResult) {
          useI18nForms().locale.value = this.locale;
        }
      });
    },
  },
});
