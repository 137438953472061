<template>
  <SNumberInput
    v-model="modelValue"
    v-bind="questionLogic.props"
    class="form-question"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "vue";

import { SNumberInput } from "@solvari/common-fe";

import type { FormComponentAdditionalInput } from "@/helpers/types";

import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps<{ question: FormComponentAdditionalInput }>();
const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const modelValue = defineModel<number | undefined>();

const questionLogic = useQuestionLogic(toRef(() => props.question));
</script>
