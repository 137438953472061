<template>
  <div
    class="s-address--be"
    :class="{
      's-address--be--manual': showManual,
      [`s-address--be--size-${props.size}`]: true,
    }"
  >
    <SCombobox
      v-if="!showManual"
      v-model="zipcodeCity"
      autocomplete="postal-code"
      class="s-address--be__zipcode-city"
      disable-filter
      first-item-always-active
      :items="zipcodeCitySuggestions"
      :loading="zipcodeCitySuggestionsFetching"
      :maxlength="255"
      name="zipcodeCity"
      required
      :rules="[
        localeSwitchRule(),
        patternRule([
          zipcodeRegex[locale],
          /^\D+$/iu,
          /^[1-9][0-9]{3} - \D+$/iu,
        ]),
        zipcodeCityNlBeExistsRule(locale),
      ]"
      show-required-type="none"
      :size="props.size"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="attemptApplyValidSuggestion"
    >
      <template #manualToggle>
        <SAlert class="s-address--be__manual" :size="size">
          {{ $t("address.showManual.text") }} <br />
          <SButton
            action-type="link"
            size="inline"
            :suffix-icon="faChevronRight"
            @click="showManual = true"
          >
            {{ $t("address.showManual.button") }}
          </SButton>
        </SAlert>
      </template>

      <template #localeSwitch>
        <SAlert
          class="s-address--be__locale-switch"
          color="primary"
          :prefix-icon="faCircleExclamation"
          :size="size"
        >
          {{ $t("address.localeWarning.text") }} <br />
          <SButton
            action-type="link"
            size="inline"
            :suffix-icon="faChevronRight"
            @click="switchLocale"
          >
            {{ $t("address.localeWarning.button") }}
          </SButton>
        </SAlert>
      </template>
    </SCombobox>

    <template v-else>
      <STextInput
        v-model="zipcodeModel"
        autocomplete="postal-code"
        class="s-address--be__zipcode"
        :maxlength="255"
        name="zipcode"
        :pattern="zipcodeRegex[locale]"
        required
        show-required-type="none"
        :size="props.size"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />

      <STextInput
        v-model="cityModel"
        autocomplete="address-level2"
        class="s-address--be__city"
        :maxlength="255"
        name="city"
        required
        show-required-type="none"
        :size="props.size"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />
    </template>

    <SCombobox
      v-model="streetModel"
      autocomplete="address-line1"
      class="s-address--be__street"
      disable-filter
      :items="streetSuggestions"
      :loading="streetSuggestionsFetching"
      :maxlength="255"
      name="street"
      required
      :rules="[streetExistsRule()]"
      show-required-type="none"
      :size="props.size"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="streetValidationTrigger"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumberModel"
      autocomplete="address-line2"
      class="s-address--be__house-number"
      :maxlength="255"
      name="houseNumber"
      required
      show-required-type="none"
      :size="props.size"
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import {
  faChevronRight,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { computedAsync, until, watchImmediate } from "@vueuse/core";
import { type PropType, watchEffect } from "vue";
import { ref, watch } from "vue";

import type { LocaleIso } from "@/lib/helpers/locales";
import type { ValidateEvent } from "@/lib/validation/ValidationProvider/useValidation";

import {
  getStreetSuggestions,
  getZipcodeCitySuggestions,
} from "@/lib/api/address.api";
import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import SButton from "@/lib/components/atoms/button/SButton.vue";
import { size as sizeProp } from "@/lib/components/logic/atoms/input/props";
import SCombobox from "@/lib/components/molecules/combobox/SCombobox.vue";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";
import { useLocaleSwitch } from "@/lib/components/organisms/address/useLocaleSwitch";
import { reEmit } from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";
import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { alphabeticCompare } from "@/lib/helpers/strings";
import { asyncThrottle } from "@/lib/helpers/utils/throttleable";
import { eager, onCreatedIfValue } from "@/lib/validation/events";
import {
  defineRule,
  splitZipcodeCity,
  zipcodeCityNlBeExistsRule,
} from "@/lib/validation/rules";
import { patternRule } from "@/lib/validation/rules/native";

const props = defineProps({
  availableLocales: {
    type: Array as PropType<readonly LocaleIso[]>,
    default: () => [],
  },
  locale: { type: String as PropType<LocaleIso>, required: true },
  size: sizeProp,
  zipcode: { type: String as PropType<string | null>, default: "" },
  houseNumber: { type: String as PropType<string | null>, default: "" },
  street: { type: String as PropType<string | null>, default: "" },
  city: { type: String as PropType<string | null>, default: "" },
});

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string | null): void;
  (event: "update:houseNumber", houseNumber: string | null): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string | null): void;
  (event: "update:zipcode", zipcode: string | null): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit, { local: true });
const houseNumberModel = useModel("houseNumber", props, emit, { local: true });
const cityModel = useModel("city", props, emit, { local: true });
const streetModel = useModel("street", props, emit, { local: true });

const showManual = ref(false);

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  ZipcodeCity
 */
const zipcodeCity = ref("");
watch(zipcodeCity, (zipcodeCity) => {
  const { zipcode, city } = splitZipcodeCity(zipcodeCity);

  zipcodeModel.value = zipcode || "";
  cityModel.value = city;
});

watchEffect(() => {
  if (zipcodeModel.value && cityModel.value) {
    zipcodeCity.value = `${zipcodeModel.value} - ${cityModel.value}`;
    return;
  }
  if (zipcodeModel.value) {
    zipcodeCity.value = zipcodeModel.value;
  }
  if (cityModel.value) {
    zipcodeCity.value = cityModel.value;
  }
});

const suggestZipcodeCity = asyncThrottle(getZipcodeCitySuggestions);

const zipcodeCitySuggestionsFetching = ref(false);
const zipcodeCitySuggestions = computedAsync(
  async () => {
    const suggestions = await suggestZipcodeCity({
      city: cityModel.value,
      locale: props.locale,
      zipcode: zipcodeModel.value,
    });
    return suggestions.map(({ zipcode, city }) => {
      const zipcodeCity = `${zipcode} - ${city}`;
      return {
        value: zipcodeCity,
        label: zipcodeCity,
        zipcode,
        city,
      };
    });
  },
  [],
  {
    evaluating: zipcodeCitySuggestionsFetching,
    onError: () => {
      showManual.value = true;
    },
  },
);

async function attemptApplyValidSuggestion() {
  const suggestions = await suggestZipcodeCity({
    city: cityModel.value,
    locale: props.locale,
    zipcode: zipcodeModel.value,
  });

  const validSuggestion = suggestions.find(
    (suggestion) =>
      suggestion.zipcode === zipcodeModel.value ||
      alphabeticCompare(suggestion.city, cityModel.value!),
  );

  if (validSuggestion) {
    zipcodeCity.value = `${validSuggestion.zipcode} - ${validSuggestion.city}`;
  }
}
void attemptApplyValidSuggestion();

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    return !showManual.value
      ? await suggestStreet({
          locale: props.locale as "fr-BE" | "nl-BE",
          zipcode: zipcodeModel.value,
          street: streetModel.value,
        })
      : [];
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = defineRule({
  name: "exists",
  validate: async (street: unknown) => {
    if (!street || typeof street !== "string") {
      return false;
    }
    await until(streetSuggestionsFetching).toBe(false);
    return (
      showManual.value ||
      streetSuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, street),
      )
    );
  },
  events: ["blur", "update:zipcode", "update:showManual"],
  color: "warning",
  blocking: false,
});

function streetValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcodeModel, () => {
    if (streetModel.value) {
      void validateEvent("update:zipcode");
    }
  });

  watchImmediate(showManual, () => {
    if (streetModel.value) {
      void validateEvent("update:showManual");
    }
  });
}
</script>

<style lang="postcss">
.s-address--be {
  @apply grid;
  grid-template-areas:
    "zipcode-city zipcode-city zipcode-city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &__manual,
  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &--manual {
    grid-template-areas:
      "zipcode city city"
      "street street house-number";
  }

  &--size {
    &-sm {
      @apply gap-3;
    }

    &-md {
      @apply gap-4;
    }

    &-lg {
      @apply gap-5;
    }
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }
}
</style>
