<template>
  <SFileInput
    v-model="modelValue"
    v-bind="questionLogic.props"
    accept=".jpg, .jpeg, .png, .webp, .gif, .avif, .svg, .tif, .tiff, .bmp, .pdf"
    class="form-question"
    max-size="1000"
    multiple
    :rules="[maxAmount(5, { events: ['fileUpload'] })]"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import type { SFileLocal } from "@solvari/common-fe/composables";

import { reEmit } from "@solvari/common-fe/helpers";
import { maxAmount } from "@solvari/common-fe/validation";
import { toRef } from "@vueuse/core";

import { SFileInput } from "@solvari/common-fe";

import type { FormComponentAdditionalInput } from "@/helpers/types";

import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps<{ question: FormComponentAdditionalInput }>();
const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const modelValue = defineModel<SFileLocal[] | undefined>();

const questionLogic = useQuestionLogic(toRef(() => props.question));
</script>
