import { toRef } from "vue";

import type { UseCheckboxRadioInputEmit } from "@/lib/components/logic/atoms/useCheckboxRadioInput";
import type { DefineProps } from "@/lib/composables/componentComposable";

import { size } from "@/lib/components/logic/atoms/input/props";
import * as useCheckboxRadioInput from "@/lib/components/logic/atoms/useCheckboxRadioInput";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy } from "@/lib/composables";
import {
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { mergeReactive, reactivePick } from "@/lib/helpers/reactivity";

const props = propsDefinition({
  ...useCheckboxRadioInput.props,
  ...useTooltip.scoped,
  size,
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  modelValue: { type: Boolean, default: false },
  label: { type: String, required: false },
  imageUrl: { type: String, required: false },
  imageContain: { type: Boolean, default: false },
});

const emits = useCheckboxRadioInput.emits;

type UseCheckboxRadioProps = DefineProps<typeof props>;
type UseCheckboxRadioEmit = UseCheckboxRadioInputEmit;

function use(props: UseCheckboxRadioProps, emit: UseCheckboxRadioEmit) {
  const { describedBy, ids } = useDescribedBy(
    reactivePick(props, ["tooltip"]),
    toRef(() => props.describedBy),
  );

  const inputAtom = {
    props: mergeReactive(pickProps(props, useCheckboxRadioInput.props), {
      describedBy,
    }),
    on: reEmit(emit, useCheckboxRadioInput.emits),
  };

  const tooltipAtom = {
    props: mergeReactive(props, {
      tooltipId: toRef(() => ids.tooltip),
      tooltip: props.tooltip,
    }),
  };

  return {
    inputAtom,
    tooltipAtom,
    label: toRef(() => props.label),
    disabled: toRef(() => props.disabled),
    modelValue: toRef(() => props.modelValue),
    indeterminate: toRef(() => props.indeterminate),
    imageUrl: toRef(() => props.imageUrl),
    imageContain: toRef(() => props.imageContain),
    size: toRef(() => props.size),
  };
}

export type { UseCheckboxRadioEmit, UseCheckboxRadioProps };
export { emits, props, use };
