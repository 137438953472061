<template>
  <p class="s-form__legal-disclaimer" v-html="$t('legal.disclaimer')" />
</template>

<style lang="postcss">
.s-form__legal-disclaimer {
  @apply mb-8 text-sm;

  a {
    @apply text-primary-700;
  }
}
</style>
