<template>
  <label
    class="s-checkbox-radio-image"
    :class="{
      's-checkbox-radio-image--disabled': disabled,
      's-checkbox-radio-image--checked': modelValue,
    }"
  >
    <img
      :alt="label"
      class="s-checkbox-radio-image__image"
      :class="{ 's-checkbox-radio-image__image--contain': imageContain }"
      :src="imageUrl"
    />
    <span class="s-checkbox-radio-image__content">
      <SCheckboxRadioInput v-bind="inputAtom.props" v-on="inputAtom.on" />
      <span class="s-checkbox-radio-image__label" v-html="label" />
      <STooltipInfo
        v-if="tooltipAtom.props.tooltip"
        v-bind="tooltipAtom.props"
      />
    </span>
  </label>
</template>

<script setup lang="ts">
import type { UseCheckboxRadioEmit } from "@/lib/components/logic/atoms/useCheckboxRadio";

import SCheckboxRadioInput from "@/lib/components/atoms/checkbox-radio/SCheckboxRadioInput.vue";
import STooltipInfo from "@/lib/components/atoms/tooltip/STooltipInfo.vue";
import * as useCheckboxRadio from "@/lib/components/logic/atoms/useCheckboxRadio";

const props = defineProps(useCheckboxRadio.props);
const emit = defineEmits<UseCheckboxRadioEmit>();

const {
  inputAtom,
  tooltipAtom,
  label,
  disabled,
  modelValue,
  imageContain,
  imageUrl,
} = useCheckboxRadio.use(props, emit);
</script>

<style lang="postcss">
.s-checkbox-radio-image {
  @apply relative mb-0 flex cursor-pointer flex-col rounded border border-gray-200 transition-colors;

  &__image {
    @apply h-40 w-full rounded-t object-cover object-center;

    &--contain {
      @apply object-contain px-2 pt-2;
    }
  }

  &__content {
    @apply flex w-full items-center space-x-3 rounded-b bg-white p-3;
  }

  &__label {
    @apply grow text-base text-gray-900;
  }

  &--checked,
  &--indeterminate {
    @apply border-primary-700;

    .s-checkbox-radio-image__label {
      @apply font-medium;
    }
  }

  &--disabled {
    @apply cursor-default;

    .s-checkbox-radio-image {
      &__label {
        @apply text-gray-600;
      }
    }
  }
}
</style>
