<template>
  <STextInput v-bind="textInput.props" v-on="textInput.on">
    <template v-for="(_index, name) in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </STextInput>
</template>

<script setup lang="ts">
import type { UseNumberInputPresetEmit } from "@/lib/components/logic/molecules/useNumberInputPreset";

import * as useNumberInputPreset from "@/lib/components/logic/molecules/useNumberInputPreset";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";

const props = defineProps(useNumberInputPreset.props);
const emit = defineEmits<UseNumberInputPresetEmit>();

const { textInput } = useNumberInputPreset.use(props, emit);
</script>
