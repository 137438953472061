import type { PropType } from "vue";

import { toRefs } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SIconDefinition } from "@/lib/icons/fontAwesome.types";

import { color, size } from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  size,
});

const props = propsDefinition({
  color,
  prefixIcon: {
    type: Object as PropType<SIconDefinition>,
    required: false,
  },
  suffixIcon: {
    type: Object as PropType<SIconDefinition>,
    required: false,
  },
  ...scoped,
});

type UseAlertProps = DefineProps<typeof props>;

function use(props: UseAlertProps) {
  const propRefs = toRefs(props);
  return {
    size: propRefs.size,
    color: propRefs.color,
    prefixIcon: propRefs.prefixIcon,
    suffixIcon: propRefs.suffixIcon,
  };
}

export type { UseAlertProps };
export { props, scoped, use };
