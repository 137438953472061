import { isObject } from "radash";

import type {
  ValidationRuleBase,
  ValidationRuleDefinition,
  ValidationRuleOverrides,
} from "@/lib/validation/validation.types";

import { useI18n } from "@/lib/composables/useI18n";

function paramsToMessageParams<Params>(
  fieldLabel: string,
  params: Params,
  ruleName: string,
) {
  if (!params) {
    return { _field_: fieldLabel };
  }
  if (isObject(params)) {
    return { _field_: fieldLabel, ...params };
  }
  return { _field_: fieldLabel, [ruleName]: String(params) };
}

function stringMessage(
  message: string,
  messageParams: { [key: string]: string; _field_: string },
) {
  const regex = new RegExp(
    Object.keys(messageParams)
      .map((param) => `{${param}}`)
      .join("|"),
    "g",
  );
  return message.replace(
    regex,
    (matched) =>
      messageParams[matched.substring(1, matched.length - 1)] ?? matched,
  );
}

function autoTranslateMessage(
  fieldName: string,
  messageParams: { [key: string]: string; _field_: string },
  ruleName: string,
) {
  const { tr, trOptional } = useI18n();

  return (
    trOptional(`fields.${fieldName}.validation.${ruleName}`, messageParams) ??
    tr(`validation.${ruleName}`, messageParams)
  );
}

function getMessageFunction<ModelValue, Params>(
  rule: ValidationRuleDefinition<ModelValue, Params>,
  params: Params,
): ValidationRuleBase<ModelValue, Params>["message"] {
  const message = rule.message;

  return (fieldLabel, value, fieldName) => {
    if (typeof message === "function") {
      return message(fieldLabel, value, fieldName, params);
    }

    const messageParams = paramsToMessageParams(fieldLabel, params, rule.name);

    if (message) {
      return stringMessage(message, messageParams);
    }

    return autoTranslateMessage(fieldName, messageParams, rule.name);
  };
}

function defineRule<
  ModelValue,
  Params,
  Return extends Promise<boolean> | boolean,
>(rule: ValidationRuleDefinition<ModelValue, Params, Return>) {
  return (
    params?: Params,
    overrides?: ValidationRuleOverrides<ModelValue, Params>,
  ): ValidationRuleBase<ModelValue, Params, Return> => {
    const finalRule: ValidationRuleDefinition<ModelValue, Params, Return> =
      overrides ? { ...rule, ...overrides } : rule;

    return {
      ...finalRule,
      validate: (value: ModelValue) => rule.validate(value, params!),
      message: getMessageFunction(finalRule, params!),
      params: params!,
    };
  };
}

export { defineRule };
