import { defineStore } from "pinia";
import { ref, watchEffect } from "vue";

import { useIsVisible } from "@/composables/useIsVisible";

/*
  Stores data that doesn't fit anywhere else, this should be as small as possible
 */
export const useMetaStore = defineStore("meta", () => {
  const rootElement = ref<HTMLElement | null>(null);
  const isVisible = useIsVisible(rootElement, {
    rootMargin: "-100px",
  });

  const translations = ref<Record<string, unknown> | null>(null);

  function initStoreWatchers(props: {
    rootElement: HTMLElement | null;
    translations: Record<string, unknown> | null;
  }) {
    watchEffect(() => (rootElement.value = props.rootElement));
    watchEffect(() => (translations.value = props.translations));
  }

  return { rootElement, isVisible, translations, initStoreWatchers };
});
