<template>
  <legend class="form-header">{{ props.question.label }}</legend>
</template>

<script setup lang="ts">
import type { FormComponentStatic } from "@/helpers/types";

const props = defineProps<{ question: FormComponentStatic }>();
</script>

<style lang="postcss">
.form-header {
  @apply mb-4 block text-2xl font-normal text-primary-700;
}
</style>
