import type { PropType, Ref } from "vue";

import { toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { InputValue } from "@/lib/helpers/types";

import {
  propsDefinition,
  propsToDefaults,
} from "@/lib/composables/componentComposable";
import { mergeReactive } from "@/lib/helpers/reactivity";

import { useOptionsStoreItems } from "./useOptionsStoreItems";
import { useOptionsStoreModel } from "./useOptionsStoreModel";

type OptionValue = boolean | number | string;

type OptionItem = {
  children?: OptionItem[];
  disabled: boolean;
  id: string;
  isActive: boolean;
  isOpen: boolean;
  isParent: boolean;
  label: string;
  modelValue: boolean;
  name: string;
  required: boolean;
  type: "checkbox" | "radio";
  value: OptionValue;
};

type OptionItemProp = {
  children?: OptionItemProp[];
  disabled?: boolean;
  imageUrl?: string;
  isOpen?: boolean;
  label: string;
  required?: boolean;
  tooltip?: string;
  value: OptionValue;
};

const useOptionsStoreScoped = propsDefinition({
  items: {
    type: [Array, Object] as PropType<
      Record<number | string, string> | string[] | readonly OptionItemProp[]
    >,
    default: () => [],
  },
  name: { type: String, required: true },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
});

const useOptionsStoreProps = useOptionsStoreScoped;

type UseOptionsStoreProps = Partial<DefineProps<typeof useOptionsStoreProps>>;

function useOptionsStore(
  rawModel: Ref<InputValue<OptionValue | readonly OptionValue[]>>,
  propsParam: UseOptionsStoreProps,
) {
  const props = mergeReactive(
    propsToDefaults(useOptionsStoreProps),
    propsParam,
  );

  const model = useOptionsStoreModel(
    rawModel,
    toRef(() => props.multiple),
  );

  const modelItems = useOptionsStoreItems(
    toRef(() => props.items),
    model.modelValue,
    toRef(() => props.name),
    toRef(() => props.multiple),
    toRef(() => props.required),
    toRef(() => props.disabled),
  );

  function applyClosestMatch(label: string, threshold = 1) {
    const item = modelItems.matchItemByLabel(label, threshold);
    if (item) {
      model.add(item.value);
    }
    return item;
  }

  // Replicates radio & checkbox logic
  function tryToggleValue(value: OptionValue) {
    const item = modelItems.findItem(value, modelItems.normalizedItems);
    if (!item || item.disabled) {
      return;
    }
    if (!item.modelValue) {
      model.add(value);
    } else if (props.multiple) {
      model.remove(value);
    }
  }

  function setChecked(
    checked: boolean,
    value: OptionValue | readonly OptionValue[],
  ) {
    if (checked) {
      model.add(value);
    } else {
      model.remove(value);
    }
  }

  return {
    addValue: model.add,
    removeValue: model.remove,
    tryToggleValue,
    setChecked,
    applyClosestMatch,
    activeItemValue: modelItems.activeItemValue,
    setIsOpen: modelItems.setIsOpen,
    findItem: modelItems.findItem,
    findItemIndex: modelItems.findItemIndex,
    items: modelItems.mappedItems,
    allItems: modelItems.allItems,
    checkedItems: modelItems.checkedItems,
    typedItems: modelItems.typedItems,
  };
}

export type { OptionItem, OptionItemProp, OptionValue, UseOptionsStoreProps };
export {
  useOptionsStore,
  useOptionsStoreModel,
  useOptionsStoreProps,
  useOptionsStoreScoped,
};
