<template>
  <div v-if="props.tags?.length" class="checkbox-tags">
    <div
      v-for="{ id, tag } in props.tags"
      :id="id"
      :key="id"
      class="checkbox-tags__tag"
      :class="[`checkbox-tags__tag--${getColor(tag)}`]"
    >
      {{ tr(`tags.${tag}`) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@solvari/common-fe/composables";

import type { FormComponentAnswerTag } from "@/helpers/types";

const { tr } = useI18n();

const props = defineProps<{
  tags?: { id: string; tag: FormComponentAnswerTag }[];
}>();

function getColor(tag: FormComponentAnswerTag) {
  return {
    bestPriceQuality: "accent",
    lowestPrice: "accent",
    oftenChosen: "primary",
    popular: "primary",
    recommended: "warning",
    smartChoice: "accent",
    subsidy: "primary-light",
    sustainableChoice: "accent",
    tip: "warning",
  }[tag];
}
</script>

<style lang="postcss">
.checkbox-tags {
  @apply flex flex-wrap gap-1;

  &__tag {
    @apply rounded px-2 py-1 text-sm leading-none text-white;

    &--accent {
      @apply bg-accent-600;
    }

    &--primary {
      @apply bg-primary-700;
    }

    &--warning {
      @apply bg-warning-400;
    }

    &--primary-light {
      @apply bg-primary-300;
    }
  }
}
</style>
