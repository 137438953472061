import { arrayWrap } from "@solvari/common-fe/helpers";
import { computed, type Ref } from "vue";

import type { Additional, FormComponentAll } from "@/helpers/types";

function useChildComponents(
  component: FormComponentAll,
  modelValue: Ref<Additional[number] | undefined>,
) {
  return computed(() => {
    if (!("answers" in component)) {
      return [];
    }

    return arrayWrap(modelValue.value as number[] | number).flatMap(
      (answerValue) => {
        return (
          component.answers.find(({ value }) => value === answerValue)
            ?.childComponents ?? []
        );
      },
    );
  });
}

export { useChildComponents };
