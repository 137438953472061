<template>
  <div class="s-checkbox">
    <slot name="checkboxRadio" v-bind="checkboxRadio">
      <SCheckboxRadio v-bind="checkboxRadio.props" v-on="checkboxRadio.on">
        <template v-for="(_index, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </SCheckboxRadio>
    </slot>
    <SSubtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent?.toString()" v-bind="errorProps">
      <SErrorAtom v-bind="errorProps" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { UseCheckboxEmit } from "@/lib/components/logic/molecules/useCheckbox";

import SCheckboxRadio from "@/lib/components/atoms/checkbox-radio/SCheckboxRadio.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import * as useCheckbox from "@/lib/components/logic/molecules/useCheckbox";

const props = defineProps(useCheckbox.props);
const emit = defineEmits<UseCheckboxEmit>();

defineSlots<{
  checkboxRadio: (component: typeof checkboxRadio) => never;
  // eslint-disable-next-line
  [key: string]: (data: any) => never;
}>();

const { checkboxRadio, subtextAtom, error, errorComponent, errorProps } =
  useCheckbox.use(props, emit);
</script>
