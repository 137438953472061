import type { ConsentTypes } from "@/lib/components/native/cookie-popup/state";
/*
  These helpers use Typescript to define the interface

  Extend the interface like a so:
  declare global {
    interface Cookies {
      exampleKey: exampleType
    }
  }
 */

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Cookies {
    solvariClientId: string;
    solvariCookieConsent: ConsentTypes;
  }
}

type SetCookieEvent = CustomEvent<{ name: keyof Cookies }>;

export function getCookie(name: keyof Cookies, parseJSON: false): string | null;
export function getCookie<Name extends keyof Cookies>(
  name: Name,
  parseJSON?: true,
): Cookies[Name] | null;
export function getCookie<Name extends keyof Cookies>(
  name: Name,
  parseJSON = true,
) {
  const value = new RegExp(`(?:^| )${name}=([^;]*)`).exec(document.cookie)?.[1];
  if (!value) {
    return null;
  }
  if (!parseJSON) {
    return value;
  }

  try {
    return JSON.parse(value) as Cookies[Name];
  } catch (_error) {
    return value;
  }
}

function setCookie<Name extends keyof Cookies>({
  name,
  value,
  expires,
  maxAge,
  path = "/",
}: {
  expires?: string;
  maxAge?: string;
  name: Name;
  path?: string | undefined;
  value: Cookies[Name] | null;
}) {
  const cookieParts: string[] = [];

  const stringValue = typeof value !== "string" ? JSON.stringify(value) : value;

  cookieParts.push(`${name}=${stringValue}`);
  if (expires) {
    cookieParts.push(`expires=${expires}`);
  }
  if (maxAge) {
    cookieParts.push(`max-age=${maxAge}`);
  }
  cookieParts.push(`path=${path}`);

  document.cookie = cookieParts.join("; ");

  const event: SetCookieEvent = new CustomEvent("setCookie", {
    detail: { name },
  });
  document.dispatchEvent(event);
}

function deleteCookie(name: keyof Cookies, path?: string) {
  setCookie({
    name,
    value: null,
    expires: "Thu, 01 Jan 1970 00:00:00 UTC",
    path,
  });
}

function onInitCookie<Name extends keyof Cookies>(
  name: Name,
  callback: (cookie: Cookies[Name]) => Promise<void> | void,
) {
  const cookie = getCookie(name);
  if (cookie) {
    void callback(cookie);
    return;
  }

  const abortController = new AbortController();
  const signal = abortController.signal;

  const setCookieEventListener = (({ detail }: SetCookieEvent) => {
    if (detail.name === name) {
      const eventCookie = getCookie(name);
      if (eventCookie) {
        void callback(eventCookie);
      }
      abortController.abort();
    }
  }) as EventListener;

  document.addEventListener("setCookie", setCookieEventListener, {
    passive: true,
    signal,
  });
}

function onSetCookie<Name extends keyof Cookies>(
  name: Name,
  callback: (cookie: Cookies[Name]) => void,
) {
  const setCookieEventListener = (({ detail }: SetCookieEvent) => {
    if (detail.name === name) {
      const eventCookie = getCookie(name);
      if (eventCookie) {
        callback(eventCookie);
      }
    }
  }) as EventListener;

  document.addEventListener("setCookie", setCookieEventListener, {
    passive: true,
  });
}

export { deleteCookie, onInitCookie, onSetCookie, setCookie };
