import { computed, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import * as useProgress from "@/lib/components/logic/atoms/useProgress";
import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";
import { mergeReactive } from "@/lib/helpers/reactivity";

const props = propsDefinition({
  ...useProgress.props,
  modelValue: {
    type: Number,
    default: 1,
  },
});
const emits = emitsDefinition(["update:modelValue"]);

type UseStepperMinimalProps = DefineProps<typeof props>;
type UseStepperMinimalEmit = (
  event: "update:modelValue",
  value: number | undefined,
) => void;

function use(props: UseStepperMinimalProps, emit: UseStepperMinimalEmit) {
  const modelValue = useModel("modelValue", props, emit);
  const max = toRef(() => props.max);

  function previousStep() {
    if (modelValue.value > 0) {
      modelValue.value--;
    }
  }

  const showPreviousButton = computed(() => modelValue.value > 1);

  const progressAtom = {
    props: mergeReactive(props, { modelValue }),
  };

  return {
    progressAtom,
    currentStep: modelValue,
    max,
    previousStep,
    showPreviousButton,
  };
}

export type { UseStepperMinimalEmit, UseStepperMinimalProps };
export { emits, props, use };
