import type { Ref } from "vue";

import { ref, watch } from "vue";

import type { Decimals } from "@/lib/components/logic/atoms/input/props";
import type { InputValue } from "@/lib/helpers/types";

import { filterNonNumericChars, truncateDecimals } from "@/lib/helpers/numbers";

type UseNumberMaskingProps = {
  decimals: Ref<Readonly<Decimals>>;
  decimalsFill: Ref<Readonly<boolean>>;
  numberValue: Ref<number | string | null | undefined>;
};

/*
  TODO: Use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */

function useNumberMasking<Props extends UseNumberMaskingProps>({
  numberValue,
  decimals,
  decimalsFill,
}: Props) {
  const displayValue = ref("");

  /*
    Masking
    */
  function getMasked(value: string) {
    let sanitizedValue = filterNonNumericChars(value, !!decimals.value);

    if (!sanitizedValue.length) {
      return "";
    }

    if (sanitizedValue.startsWith(".")) {
      sanitizedValue = `0${sanitizedValue}`;
    }

    if (sanitizedValue.endsWith(".") || isNaN(Number(sanitizedValue))) {
      return sanitizedValue;
    }

    return truncateDecimals(sanitizedValue, decimals.value);
  }

  /*
   Sanitizing
  */
  function getSanitized(value: InputValue<number | string>) {
    if (!value && value !== 0) {
      return "";
    }
    let sanitizedValue = filterNonNumericChars(String(value));

    if (!sanitizedValue.length) {
      return "";
    }

    if (isNaN(Number(sanitizedValue))) {
      return sanitizedValue;
    }

    sanitizedValue = truncateDecimals(sanitizedValue, decimals.value);

    if (decimalsFill.value && decimals.value !== true) {
      return Number(sanitizedValue).toFixed(Number(decimals.value));
    }

    return sanitizedValue;
  }

  function getNumber(value: string) {
    return value ? Number(value) : null;
  }

  /*
    Syncing of displayValue and numberValue
  */
  function isNumberValueAndDisplayValueSynced() {
    // Number("") === 0, we want to treat this as null
    if (!displayValue.value) {
      return numberValue.value === null;
    }
    if (typeof numberValue.value !== "number") {
      return false;
    }

    const numberDisplayValue = Number(displayValue.value);

    // NaN does not === NaN, so we check separately
    return (
      numberValue.value === numberDisplayValue ||
      (isNaN(numberValue.value) && isNaN(numberDisplayValue))
    );
  }

  watch(displayValue, (displayValue) => {
    if (!isNumberValueAndDisplayValueSynced()) {
      numberValue.value = getNumber(displayValue);
    }
  });

  watch(
    numberValue,
    (numberValue) => {
      if (!isNumberValueAndDisplayValueSynced()) {
        displayValue.value = getSanitized(numberValue);
      }
    },
    { immediate: true },
  );

  return { displayValue, getMasked, getSanitized };
}

export type { UseNumberMaskingProps };
export { useNumberMasking };
