<template>
  <div
    :ref="container.ref"
    class="s-autocomplete"
    :class="{
      's-autocomplete--is-open': isOpen,
      's-autocomplete--is-focused': isFocused,
    }"
    v-on="container.on"
  >
    <SLabelAtom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />

    <SDescription
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />

    <SInputAtom
      :ref="inputAtom.ref"
      v-bind="inputAtom.props"
      v-on="inputAtom.on"
    />

    <STransition name="fade">
      <keep-alive>
        <slot v-if="isOpen" name="listbox" v-bind="listboxAtom">
          <SListbox
            v-if="isOpen"
            :ref="listboxAtom.ref"
            v-bind="listboxAtom.props"
            v-on="listboxAtom.on"
          />
        </slot>
      </keep-alive>
    </STransition>

    <SSubtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />

    <slot v-if="error" :name="errorComponent" v-bind="errorProps">
      <SErrorAtom v-bind="errorProps" />
    </slot>

    <template v-if="multiValueDisplayAtom.if">
      <div v-if="valueLabel" class="s-autocomplete__value-label">
        {{ valueLabel }}
      </div>
      <SChipsDisplay
        v-bind="multiValueDisplayAtom.props"
        removable
        v-on="multiValueDisplayAtom.on"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { UseAutocompleteEmit } from "@/lib/components/logic/molecules/useAutocomplete";

import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SInputAtom from "@/lib/components/atoms/input/SInputAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SListbox from "@/lib/components/atoms/listbox/SListbox.vue";
import SChipsDisplay from "@/lib/components/atoms/multi-value-display/SChipsDisplay.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import STransition from "@/lib/components/behaviour/STransition.vue";
import * as useAutocomplete from "@/lib/components/logic/molecules/useAutocomplete";

const props = defineProps(useAutocomplete.props);
const emit = defineEmits<UseAutocompleteEmit>();

const {
  container,
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  inputAtom,
  subtextAtom,
  listboxAtom,
  isOpen,
  isFocused,
  multiValueDisplayAtom,
  valueLabel,
  error,
  errorComponent,
  errorProps,
} = useAutocomplete.use(props, emit);
</script>

<style lang="postcss">
.s-autocomplete {
  &--is-open .s-input-icon-atom--suffix .fa-chevron-down {
    transform: rotate(180deg);
  }

  &__value-label {
    @apply -mb-1 mt-2 text-sm text-gray-600;
  }

  .s-chips-display {
    @apply mt-2;
  }
}
</style>
