<template>
  <SButtonGroup
    v-model="modelValue"
    v-bind="questionLogic.props"
    class="form-question"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  >
    <template #checkboxRadio="checkboxRadio">
      <SCheckboxRadio
        v-bind="checkboxRadio.props"
        :described-by="
          questionLogic.getItemMeta(checkboxRadio.props.value)?.describedBy
        "
        v-on="checkboxRadio.on"
      >
        <template #suffix>
          <CheckboxTags
            :tags="questionLogic.getItemMeta(checkboxRadio.props.value)?.tags"
          />
        </template>
      </SCheckboxRadio>
    </template>
  </SButtonGroup>
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "vue";

import { SButtonGroup, SCheckboxRadio } from "@solvari/common-fe";

import type { FormComponentWithAnswer } from "@/helpers/types";

import CheckboxTags from "@/components/input/CheckboxTags.vue";
import { useQuestionLogic } from "@/composables/useQuestionLogic";

const props = defineProps<{ question: FormComponentWithAnswer }>();
const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const modelValue = defineModel<number | undefined>();

const questionLogic = useQuestionLogic(toRef(() => props.question));
</script>
