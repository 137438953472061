import { alternateLocales, localeFormatsMap } from "@/lib/enums/locales";

type LocaleIso = "fr-BE" | "fr-FR" | "nl-BE" | "nl-NL";

type LocaleMorpheus = "be-FR" | "be-NL" | "fr-FR" | "nl-NL";

type LocaleCountry = "BE" | "FR" | "NL";

type LocaleLanguage = "fr" | "nl";

type LocaleDocument = LocaleIso | LocaleLanguage;

type LocaleAll = LocaleDocument | LocaleIso | LocaleMorpheus;

type LocaleAny = LocaleAll | Lowercase<LocaleAll> | Uppercase<LocaleAll>;

type LocaleFormats = {
  country: LocaleCountry;
  document: LocaleDocument;
  iso: LocaleIso;
  language: LocaleLanguage;
  morpheus: LocaleMorpheus;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface SiteConfig {
    locale: LocaleIso;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    siteConfig?: SiteConfig;
  }
}

function findLocaleFormats(locale: LocaleAny): LocaleFormats | undefined {
  return localeFormatsMap.find(({ iso, document, morpheus }) => {
    return !!Object.values({ iso, document, morpheus }).find(
      (localeFormat) => localeFormat.toLowerCase() === locale.toLowerCase(),
    );
  });
}

function localeToIso(locale: LocaleAny): LocaleIso | undefined {
  return findLocaleFormats(locale)?.iso;
}

function localeToMorpheus(locale: LocaleAny): LocaleMorpheus | undefined {
  return findLocaleFormats(locale)?.morpheus;
}

function getCountry(locale: LocaleAny): LocaleCountry | undefined {
  return findLocaleFormats(locale)?.country;
}

function getLanguage(locale: LocaleAny): LocaleLanguage | undefined {
  return findLocaleFormats(locale)?.language;
}

function localeToAlternate(locale: LocaleIso): LocaleIso {
  return alternateLocales[locale];
}

function getSiteLocale(): LocaleIso {
  return (
    window.siteConfig?.locale ??
    localeToIso(document.documentElement.lang as LocaleDocument) ??
    "nl-NL"
  );
}

export {
  getCountry,
  getLanguage,
  getSiteLocale,
  localeToAlternate,
  localeToIso,
  localeToMorpheus,
};

export type {
  LocaleAny,
  LocaleCountry,
  LocaleDocument,
  LocaleFormats,
  LocaleIso,
  LocaleLanguage,
  LocaleMorpheus,
};
