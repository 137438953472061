import type { App } from "vue";

import type {
  SentryProject,
  WindowSentry,
} from "@/lib/integrations/sentry/types";
/*
  Gets the global Sentry instance, this avoids having to include Sentry in every micro frontend
 */
function getSentry(): WindowSentry {
  return (
    window.sentry ?? {
      captureException: (error, hint) => console.error(error, hint),
      captureMessage: (message, captureContext) =>
        console.error(message, captureContext),
    }
  );
}

function addSentryProject(project: SentryProject) {
  window.sentryProjects = window.sentryProjects ?? {};
  window.sentryProjects[project.name] = project;
}

function trackVueErrors(vueApp: App) {
  vueApp.config.errorHandler = (error) => getSentry().captureException(error);
  vueApp.config.warnHandler = (error) => getSentry().captureMessage(error);
}

export { addSentryProject, getSentry, trackVueErrors };
