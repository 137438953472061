<template>
  <div v-if="files.length">
    <div class="s-preview__label">
      {{ $t("fields.default.upload.previewLabel", { amount: files.length }) }}
    </div>
    <transition-group class="s-preview" name="s-preview" tag="div">
      <div
        v-for="{ uuid, url, name, alt, mimeType } in files"
        :key="uuid"
        class="s-preview__item"
      >
        <button
          v-if="mimeType.includes('image')"
          class="s-preview__image"
          type="button"
          @click="openImage(uuid)"
        >
          <img :alt="alt" class="s-preview__image__image" :src="url" />
          <FontAwesomeIcon
            class="s-preview__image__open-icon"
            :icon="faMagnifyingGlassPlus"
          />
        </button>

        <div v-else class="s-preview__icon">
          <FontAwesomeIcon
            class="s-preview__icon__icon"
            :icon="getFileIcon(mimeType)"
          />
          <span class="s-preview__icon__text">{{ name }}</span>
        </div>

        <button
          v-if="removable"
          class="s-preview__remove"
          type="button"
          @click="remove(uuid)"
        >
          <FontAwesomeIcon class="s-preview__remove-icon" :icon="faXmark" />
        </button>
      </div>
    </transition-group>
    <SLightbox v-bind="lightboxAtom.props" v-on="lightboxAtom.on" />
  </div>
</template>

<script setup lang="ts">
import { faFile, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import {
  faMagnifyingGlassPlus,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import type { UsePreviewEmit } from "@/lib/components/logic/atoms/usePreview";

import SLightbox from "@/lib/components/atoms/lightbox/SLightbox.vue";
import * as usePreview from "@/lib/components/logic/atoms/usePreview";

const props = defineProps(usePreview.props);
const emit = defineEmits<UsePreviewEmit>();

const { files, removable, remove, openImage, lightboxAtom } = usePreview.use(
  props,
  emit,
);

function getFileIcon(mimeType: string) {
  if (mimeType.includes("pdf")) {
    return faFilePdf;
  }
  return faFile;
}
</script>

<style lang="postcss">
.s-preview {
  @apply grid gap-3 grid-cols-auto-24;

  &-enter {
    &-active {
      @apply opacity-0 transition-opacity;
    }

    &-to {
      @apply opacity-100;
    }
  }

  &__label {
    @apply mb-3 text-sm text-gray-700;
  }

  &__item {
    @apply relative h-24 w-24;
  }

  &__image,
  &__icon {
    @apply h-full w-full rounded;
  }

  &__image {
    @apply grid grid-cols-1 grid-rows-1 place-items-center;

    &__open-icon {
      @apply z-10 col-start-1 row-start-1 h-6 w-6 text-white opacity-0 transition-opacity;
    }

    &__image {
      @apply col-start-1 row-start-1 h-full w-full rounded object-cover object-center;
    }

    &::before {
      content: "";
      @apply col-start-1 row-start-1 h-full w-full rounded bg-transparent opacity-50 transition-bg;
    }

    &:hover {
      .s-preview__image__open-icon {
        @apply opacity-100;
      }

      &::before {
        @apply bg-gray-900;
      }
    }
  }

  &__icon {
    @apply flex flex-col items-center justify-center bg-gray-100 p-2 text-center text-xs text-gray-800;

    &__icon {
      @apply mb-2 h-8 w-8 text-gray-600;
    }

    &__text {
      @apply max-w-full overflow-hidden break-words;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__remove {
    @apply absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-gray-800 transition-bg;

    &-icon {
      @apply h-3 w-3 text-white;
    }

    &:hover {
      @apply bg-gray-700;
    }
  }
}
</style>
