import type { PartialDeep } from "type-fest";
import type { DeepReadonly } from "vue";

import type { LocaleMorpheus } from "@/lib/helpers/locales";

import { mergeDeep } from "@/lib/helpers/utils/mergeDeep";

type SolvariEnv = {
  auth: {
    attributionUserId: string | null;
    jwtToken: string | null;
  };
  config: {
    envBE: "acceptance" | "develop" | "local" | "production" | "testing";
    envFE: "development" | "production" | "staging";
    isDev: boolean;
    lang: "fr" | "nl";
    localeCode: LocaleMorpheus;
    urlPrefix: "fr" | "nl";
  };
  integrations: {
    algolia: {
      applicationId: string;
      env: "dev" | "prd" | "stg";
      publicSearchApiKey: string;
    };
    pusher: {
      appId: string;
      cluster: string;
      key: string;
      secret: string;
    };
    sentry: string;
  };
  network: {
    admin: {
      base: string;
      baseWithPrefix: string;
    };
    api: {
      base: string;
    };
    argus: {
      base: string;
    };
    cdn: {
      morpheus: string;
    };
    client: {
      base: string;
      baseWithPrefix: string;
      "nl-NL": string;
    };
    pro: {
      base: string;
      baseWithPrefix: string;
    };
  };
};

type PartialSolvariEnv = DeepReadonly<PartialDeep<SolvariEnv>>;
type FullSolvariEnv = DeepReadonly<SolvariEnv>;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    solvariEnv?: PartialSolvariEnv;
  }
}

/*
  This will cause object property access to throw if a key is not defined.
  By default, property access will just return undefined, so this is stricter.
 */
function throwIfPropertyUndefined<
  ObjectType extends Record<PropertyKey, unknown>,
>(object: ObjectType) {
  return new Proxy<ObjectType>(object, {
    get(target: Record<PropertyKey, unknown>, prop: PropertyKey): unknown {
      const value = target[prop];

      if (typeof prop === "symbol") {
        return value;
      }

      if (value === undefined) {
        throw new Error(
          `Tried to get solvariEnv entry '${prop}' but it was not defined`,
        );
      }
      if (typeof value === "object" && value !== null) {
        return throwIfPropertyUndefined(value as Record<PropertyKey, unknown>);
      }
      return value;
    },
  });
}

let env: PartialSolvariEnv | null = null;

function getDotEnv<Return extends string = string>(
  key: string,
  variable: unknown,
): Return {
  if (typeof variable !== "string" || variable === "") {
    throw new Error(
      `Tried to get .env variable '${key}' but it was undefined or empty`,
    );
  }
  return variable as Return;
}

/*
  This will store the env variables in a local variable.
  Any access within this build will go to the same object
 */
function initEnv(
  dotEnv: PartialSolvariEnv,
  overrides?: PartialSolvariEnv,
): PartialSolvariEnv {
  if (env !== null) {
    throw new Error(
      "Tried to initialize env object but it was already initialized",
    );
  }
  env = overrides
    ? (mergeDeep(dotEnv, overrides) as PartialSolvariEnv)
    : dotEnv;
  return env;
}

/*
  This will throw if an accessed key is not defined instead of just returning undefined
 */
function getEnv<
  EnvReturn extends PartialSolvariEnv = FullSolvariEnv,
>(): EnvReturn {
  if (env === null) {
    throw new Error("Tried to get env object before initialization");
  }
  return throwIfPropertyUndefined(env) as EnvReturn;
}

export type { PartialSolvariEnv };
export { getDotEnv, getEnv, initEnv };
