import type { LocaleIso } from "@solvari/common-fe/helpers";

import { defineI18n } from "@solvari/common-fe/composables";
import { mergeDeep } from "@solvari/common-fe/helpers";

import { useAbTestsStore } from "@/plugins/store/abTests";
import { useFormStore } from "@/plugins/store/form";
import { useMetaStore } from "@/plugins/store/meta";

const fallbackMessages = {
  "nl-NL": {
    loadingError: {
      title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
      text: "Probeer het later nog eens. Onze excuses voor het ongemak!",
    },
  },
  "nl-BE": {
    loadingError: {
      title: "Oeps! Er gaat iets mis met het ophalen van dit formulier.",
      text: "Probeer het later nog eens. Onze excuses voor het ongemak!",
    },
  },
  "fr-BE": {
    loadingError: {
      title: "Oups ! Nous n’arrivons pas à charger ce formulaire.",
      text: "Veuillez réessayer plus tard. Désolé pour le désagrément !",
    },
  },
  "fr-FR": {
    loadingError: {
      title: "Oups ! Nous n’arrivons pas à charger ce formulaire.",
      text: "Veuillez réessayer plus tard. Désolé pour le désagrément !",
    },
  },
};

const useI18nForms = defineI18n((locale: LocaleIso) => {
  const translations = useFormStore().formGetResult?.translations ?? {};
  const translationsProp = useMetaStore().translations?.[locale] ?? {};
  const abTestTranslations = useAbTestsStore().translations?.[locale] ?? {};

  return (
    [
      fallbackMessages[locale],
      translations,
      translationsProp,
      abTestTranslations,
    ] as Record<string, unknown>[]
  ).reduce(mergeDeep, {}) as unknown;
});

export { useI18nForms };
