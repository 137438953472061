import type { PropType } from "vue";

import { v4 as createUuid } from "uuid";
import { computed, reactive, ref, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile, SFileLocal } from "@/lib/composables/useFileUpload";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useDescribedBy } from "@/lib/composables/useDescribedBy";
import { useI18n } from "@/lib/composables/useI18n";
import { useModel } from "@/lib/composables/useModel";

const scoped = propsDefinition({
  name: { type: String, required: true, default: "" },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  modelValue: {
    type: Array as PropType<(SFile | SFileLocal)[]>,
    default: () => [],
  },
  removable: { type: Boolean, default: true },
  accept: { type: String, required: false },
  placeholder: { type: String, required: false },
  hint: { type: String, required: false },
});

const props = propsDefinition({
  ...scoped,
  id: { type: String, required: false },
  invalid: { type: Boolean, default: false },
  describedBy: { type: String, required: false },
});

const emits = emitsDefinition(["update:modelValue"]);

type UseUploadProps = DefineProps<typeof props>;
type UseUploadEmit = (
  event: "update:modelValue",
  value: readonly (SFile | SFileLocal)[],
) => void;

function fileToSFileLocal(file: File): SFileLocal {
  return {
    uuid: createUuid(),
    url: URL.createObjectURL(file),
    name: file.name,
    alt: file.name,
    size: file.size,
    mimeType: file.type,
    file,
  };
}

function use(props: UseUploadProps, emit: UseUploadEmit) {
  const modelValue = useModel("modelValue", props, emit);

  const isDraggingOver = ref(false);
  function dragenter() {
    if (!props.disabled) {
      isDraggingOver.value = true;
    }
  }
  function dragleave() {
    isDraggingOver.value = false;
  }

  function onChange({ target }: Event) {
    const newFiles = [...(target as HTMLInputElement).files!].map(
      fileToSFileLocal,
    );
    (target as HTMLInputElement).value = "";
    if (!newFiles.length) {
      return;
    }
    modelValue.value = props.multiple
      ? [...modelValue.value, ...newFiles]
      : newFiles;
  }

  const { trOptional, tr } = useI18n();

  const placeholder = computed(() => {
    return (
      props.placeholder ??
      trOptional(`fields.${props.name}.placeholder`) ??
      tr("fields.default.upload.placeholder")
    );
  });

  const hint = computed(() => {
    return (
      props.hint ??
      trOptional(`fields.${props.name}.hint`) ??
      tr("fields.default.upload.hint")
    );
  });

  const { describedBy, ids } = useDescribedBy(
    reactive({ placeholder, hint }),
    () => props.describedBy,
  );

  const input = {
    props: reactive({
      id: toRef(() => props.id),
      name: toRef(() => props.name),
      type: "file",
      multiple: toRef(() => props.multiple),
      accept: toRef(() => props.accept),
      required: toRef(() => props.required),
      disabled: toRef(() => props.disabled),
      "aria-invalid": toRef(() => (props.invalid ? "true" : undefined)),
      "aria-describedby": describedBy,
    }),
    on: { dragenter, dragleave, drop: dragleave, change: onChange },
  };

  return {
    input,
    isDraggingOver,
    disabled: toRef(() => props.disabled),
    invalid: toRef(() => props.invalid),
    placeholder,
    placeholderId: toRef(() => ids.placeholder),
    hint,
    hintId: toRef(() => ids.hint),
  };
}

export { emits, fileToSFileLocal, props, scoped, use };
export type { UseUploadEmit, UseUploadProps };
