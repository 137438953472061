/*
  The thick client (as opposed to the thin client) can communicate with the backend.
  This requires extra dependencies like ky and sentry, hence the separation
 */

import type { DeepReadonly } from "vue";

import { omit } from "radash";
import { v4 as createUuid } from "uuid";

import type { ConsentTypes } from "@/lib/components/native/cookie-popup/state";

import { getArgusEvents, getSessionUuid } from "@/lib/argus/client/thinClient";
import {
  getMarketingParamsFromUrl,
  type MarketingParams,
} from "@/lib/argus/events/marketingParams";
import { argusGetSummary } from "@/lib/argus/service/argus.api";
import {
  type ArgusEvent,
  type ArgusEvents,
  scheduleBackendSync,
} from "@/lib/argus/service/backendSync";
import { getCookie } from "@/lib/helpers/cookies";
import { sSessionStorage } from "@/lib/helpers/localStorage";
import { getEnv } from "@/lib/helpers/solvariEnv";
import { keysToSnakeCase } from "@/lib/helpers/utils";
import { getSentry } from "@/lib/integrations/sentry/client";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    argusClient?: {
      createArgusEvent?: typeof createArgusEvent;
    };
  }
}

function createArgusEvent<Type extends keyof ArgusEvents>(
  type: Type,
  data: DeepReadonly<ArgusEvents[Type]>,
): ReturnType<typeof scheduleBackendSync> {
  if (!window.argusClient) {
    window.argusClient = {};
  }
  if (!window.argusClient.createArgusEvent) {
    window.argusClient.createArgusEvent = (type, data) => {
      const event = {
        type,
        data,
        uuid: createUuid(),
        createdAt: new Date().toISOString(),
        status: "unsynced",
        syncAttempts: [],
      } satisfies ArgusEvent;

      sSessionStorage.updateItem("argusEvents", (events) => {
        return events ? [...events, event] : [event];
      });

      return scheduleBackendSync();
    };
  }

  return window.argusClient.createArgusEvent(type, data);
}

type Metadata = MarketingParams & {
  argusUuid: string;
  cookieConsent: ConsentTypes | null;
  currentPage: string;
  currentPageReferrer: string | null;
  domain: string;
  experiments: Record<string, number | string>;
  landingPage: string | null;
  landingPageReferrer: string | null;
  userAgent: string;
};

function getFallbackMetadata(): Metadata {
  const currentPageMarketingParams = getMarketingParamsFromUrl();
  const referrerMarketingParams = document.referrer
    ? getMarketingParamsFromUrl(new URL(document.referrer))
    : null;

  const marketingParams = Object.values(currentPageMarketingParams).some(
    Boolean,
  )
    ? currentPageMarketingParams
    : (referrerMarketingParams ?? currentPageMarketingParams);

  return {
    argusUuid: getSessionUuid() ?? "",
    domain: window.location.hostname,
    currentPage: window.location.href,
    currentPageReferrer: document.referrer || null,
    userAgent: navigator.userAgent,
    cookieConsent: getCookie("solvariCookieConsent"),
    landingPage: null,
    landingPageReferrer: null,
    experiments: {},
    ...marketingParams,
  };
}

function isEventOfType<Type extends keyof ArgusEvents>(
  event: ArgusEvent,
  type: Type,
): event is ArgusEvent<Type> {
  return event.type === type;
}

function applyPageViewEvent(
  projection: Partial<Metadata>,
  event: ArgusEvent<"page_view">,
) {
  if (event.data.pageType === "referrer") {
    if (!projection.landingPage) {
      projection.landingPageReferrer = event.data.url;
    }
    projection.currentPageReferrer = event.data.url;
    return;
  }

  if (!projection.landingPage) {
    projection.landingPage = event.data.url;
  } else {
    projection.currentPageReferrer = projection.currentPage;
  }

  projection.domain = new URL(event.data.url).hostname;
  projection.currentPage = event.data.url;
}

function getClientSideArgusProjection(
  events: ArgusEvent[] | null = getArgusEvents(),
) {
  if (!events?.length) {
    return getFallbackMetadata();
  }
  return events.reduce((projection: Metadata, event) => {
    if (isEventOfType(event, "init")) {
      projection.userAgent = event.data.userAgent;
    }

    if (isEventOfType(event, "page_view")) {
      applyPageViewEvent(projection, event);
    }

    if (isEventOfType(event, "marketing_params")) {
      projection = { ...projection, ...event.data };
    }

    if (isEventOfType(event, "cookie_consent")) {
      projection.cookieConsent = event.data.consentTypes;
    }

    if (isEventOfType(event, "google_optimize")) {
      projection.experiments[event.data.experimentId] = event.data.variantId;
    }

    return projection;
  }, getFallbackMetadata());
}

async function getMetadataFromArgus() {
  const uuid = getSessionUuid();
  if (!uuid || getEnv().config.envFE === "development") {
    return getClientSideArgusProjection();
  }

  const response = await argusGetSummary(uuid);
  if (response instanceof Error) {
    getSentry().captureException(response, { tags: { project: "argus-fe" } });
    return getClientSideArgusProjection();
  }

  return { argusUuid: response.uuid, ...omit(response, ["uuid"]) };
}

async function getMetadataFromArgusSnakeCase() {
  return keysToSnakeCase(await getMetadataFromArgus());
}

export type { Metadata };
export {
  createArgusEvent,
  getClientSideArgusProjection,
  getMetadataFromArgus,
  getMetadataFromArgusSnakeCase,
};
export * from "./gtm";
