import type { PropType } from "vue";

import { computed, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type {
  OptionItemProp,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

import { propsDefinition } from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  removable: { type: Boolean, default: false },
});

type UseMultiValueDisplayGeneric = {
  item: Pick<OptionItemProp, "label" | "value">;
};

const props = <Item extends UseMultiValueDisplayGeneric["item"]>() =>
  propsDefinition({
    ...scoped,
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    variant: { type: String as PropType<"clear" | "opaque">, default: "clear" },
  });

const emits = ["removeValue"];

type UseMultiValueDisplayProps<
  Item extends UseMultiValueDisplayGeneric["item"],
> = DefineProps<ReturnType<typeof props<Item>>>;

type UseMultiValueDisplayEmit<
  Item extends UseMultiValueDisplayGeneric["item"],
> = (event: "removeValue", value: Item["value"]) => void;

function use<Item extends UseMultiValueDisplayGeneric["item"]>(
  props: UseMultiValueDisplayProps<Item>,
  emit: UseMultiValueDisplayEmit<Item>,
) {
  function removeValue(removedValue: OptionValue) {
    if (props.removable) {
      emit("removeValue", removedValue);
    }
  }

  const itemsLabels = computed(() => props.items.map(({ label }) => label));

  const tag = computed(() => (props.removable ? "button" : "span"));

  return {
    shouldShow: toRef(() => Boolean(props.items.length)),
    items: toRef(() => props.items),
    itemsLabels,
    removeValue,
    removable: toRef(() => props.removable),
    tag,
    variant: toRef(() => props.variant),
  };
}

export type {
  UseMultiValueDisplayEmit,
  UseMultiValueDisplayGeneric,
  UseMultiValueDisplayProps,
};
export { emits, props, scoped, use };
