import type { PropType } from "vue";

import { reactive, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useElementValue } from "@/lib/composables/useElementValue";

const props = propsDefinition({
  name: { type: String, required: true },
  id: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  indeterminate: { type: Boolean, default: false },
  value: { type: [Boolean, String, Number], required: true },
  modelValue: { type: Boolean, default: false },
  type: { type: String as PropType<"checkbox" | "radio">, default: "checkbox" },
  describedBy: { type: String, required: false },
});

type UseCheckboxRadioInputProps = DefineProps<typeof props>;
type UseCheckboxRadioInputEmit = {
  (event: "blur" | "focus", name: string): void;
  (event: "update:modelValue", value: boolean): void;
};
const emits = emitsDefinition(["update:modelValue", "focus", "blur"]);

function use(
  props: UseCheckboxRadioInputProps,
  emit: UseCheckboxRadioInputEmit,
) {
  function onInput({ target }: Event) {
    const checked = (target as HTMLInputElement).checked;
    emit("update:modelValue", checked);
  }

  function onFocus() {
    emit("focus", props.name);
  }

  function onBlur() {
    emit("blur", props.name);
  }

  return {
    input: {
      props: reactive({
        id: toRef(() => props.id),
        name: toRef(() => props.name),
        value: useElementValue(toRef(() => props.value)),
        disabled: toRef(() => props.disabled),
        required: toRef(() => props.required),
        indeterminate: toRef(() => props.indeterminate),
        type: toRef(() => props.type),
        checked: toRef(() => props.modelValue),
        "aria-describedby": toRef(() => props.describedBy),
      }),
      on: {
        click: onInput,
        focus: onFocus,
        blur: onBlur,
      },
    },
    type: toRef(() => props.type),
    disabled: toRef(() => props.disabled),
    modelValue: toRef(() => props.modelValue),
    indeterminate: toRef(() => props.indeterminate),
  };
}

export type { UseCheckboxRadioInputEmit, UseCheckboxRadioInputProps };
export { emits, props, use };
