import type { WindowMessages } from "@/lib/helpers/iframes/types";

type SolvariEvent<
  EventName extends keyof WindowMessages = keyof WindowMessages,
> = WindowMessages[EventName] & {
  solvariEvent: EventName;
};

type WindowMessageContext = {
  iframe?: HTMLIFrameElement;
  window: MessageEventSource;
};

function isCorrectSolvariEvent<EventName extends keyof WindowMessages>(
  event: EventName,
  data: unknown,
): data is SolvariEvent<EventName> {
  return (
    !!data &&
    typeof data === "object" &&
    "solvariEvent" in data &&
    data.solvariEvent === event
  );
}

const iframes = document.getElementsByTagName("iframe");

/*
  Register your messages in the global WindowMessages interface to get cross domain type safety<br>
  event: Record<string, unknown>;
 */
function postWindowMessage<EventName extends keyof WindowMessages>(
  event: EventName,
  data: WindowMessages[EventName],
  targetWindow: Window,
) {
  targetWindow.postMessage(
    {
      solvariEvent: event,
      ...data,
    } satisfies SolvariEvent,
    "*",
  );
}

function emitWindowMessage<EventName extends keyof WindowMessages>(
  event: EventName,
  data: WindowMessages[EventName],
) {
  postWindowMessage(event, data, window.parent);
}

function getSourceIframe(source: MessageEventSource | null) {
  return Array.from(iframes).find((iframe) => iframe.contentWindow === source);
}

/*
  Register your messages in the global WindowMessages interface to get cross domain type safety<br>
  event: Record<string, unknown>;
 */
function onWindowMessage<EventName extends keyof WindowMessages>(
  event: EventName,
  callback: (
    data: SolvariEvent<EventName>,
    context: WindowMessageContext,
  ) => void,
) {
  window.addEventListener("message", ({ source, data }) => {
    if (!isCorrectSolvariEvent(event, data)) {
      return;
    }

    callback(data, { window: source!, iframe: getSourceIframe(source) });
  });
}

export type { WindowMessageContext };
export { emitWindowMessage, onWindowMessage, postWindowMessage };
