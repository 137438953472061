import type {
  ConditionalKeys,
  IfAny,
  Simplify,
  UnionToIntersection,
} from "type-fest";
import type { DefaultLocaleMessageSchema, I18n } from "vue-i18n";

import { omit } from "radash";
import { getCurrentInstance, type Ref, type WritableComputedRef } from "vue";
import { computed } from "vue";
import { createI18n, useI18n as useI18nBase } from "vue-i18n";

import type { LocaleIso, LocaleMorpheus } from "@/lib/helpers/locales";

import { getSiteLocale } from "@/lib/helpers/locales";
import { memoize, singleton } from "@/lib/helpers/utils/memoize";

type ParamsToUnion<S extends string> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  S extends `${infer _}{${infer Param}}${infer Rest}`
    ? Param | ParamsToUnion<Rest>
    : never;

type FlattenTranslations<Obj, Prefix extends string = ""> = {
  [Key in keyof Obj]: Obj[Key] extends string
    ? // Give the final key the type of its params
      { [T in `${Prefix}${Key & string}`]: ParamsToUnion<Obj[Key]> }
    : // Further flatten the object with the key as prefix;
      FlattenTranslations<Obj[Key], `${Prefix}${Key & string}.`>;
}[keyof Obj];

export type MessageSchemaToTranslations<MessageSchema> = Simplify<
  UnionToIntersection<FlattenTranslations<MessageSchema>>
>;

type Tr<Translations> = {
  (key: ConditionalKeys<Translations, never>): string;
  <
    KeyWithParams extends ConditionalKeys<Translations, string>,
    Params extends Translations[KeyWithParams] &
      string = Translations[KeyWithParams] & string,
  >(
    key: KeyWithParams,
    params: Record<Params, number | string>,
  ): string;
};

type TrOptional<Translations> = {
  (key: ConditionalKeys<Translations, never>): string | undefined;
  (key: string, params?: Record<string, number | string>): string | undefined;
  <
    KeyWithParams extends ConditionalKeys<Translations, string>,
    Params extends Translations[KeyWithParams] &
      string = Translations[KeyWithParams] & string,
  >(
    key: KeyWithParams,
    params: Record<Params, number | string>,
  ): string | undefined;
};

type GeneralTranslationsFunction = {
  tr: (key: string, params?: Record<string, number | string>) => string;
  trOptional: (
    key: string,
    params?: Record<string, number | string>,
  ) => string | undefined;
};

type TypedTranslationFunctions<Translations> = {
  tr: Tr<Translations>;
  trOptional: TrOptional<Translations>;
};

type I18nInstance = I18n<
  Record<LocaleIso | LocaleMorpheus, DefaultLocaleMessageSchema>,
  Record<LocaleIso | LocaleMorpheus, unknown>,
  Record<LocaleIso | LocaleMorpheus, unknown>,
  LocaleIso | LocaleMorpheus,
  false
>;

type SI18nInstance<
  MessageSchema,
  Locale extends LocaleIso | LocaleMorpheus = LocaleIso | LocaleMorpheus,
  Translations = MessageSchemaToTranslations<MessageSchema>,
> = Omit<I18nInstance["global"], "locale" | "t"> &
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (MessageSchema extends Record<string, any>
    ? IfAny<
        Translations,
        GeneralTranslationsFunction,
        TypedTranslationFunctions<Translations>
      >
    : GeneralTranslationsFunction) & { locale: WritableComputedRef<Locale> };

const i18nInstance: { (): I18nInstance; reset: () => void } = singleton(() => {
  return createI18n<
    DefaultLocaleMessageSchema,
    LocaleIso | LocaleMorpheus,
    false
  >({
    legacy: false,
    locale: getSiteLocale(),
    fallbackLocale: "nl-NL",
    fallbackWarn: false,
    missingWarn: false,
  });
});

function useI18n<
  MessageSchema,
  Locale extends LocaleIso | LocaleMorpheus = LocaleIso | LocaleMorpheus,
  Translations = MessageSchemaToTranslations<MessageSchema>,
>(): SI18nInstance<MessageSchema, Locale, Translations>;

function useI18n() {
  const globalI18n = getCurrentInstance()
    ? useI18nBase()
    : i18nInstance().global;

  const tr = globalI18n.t as (
    key: string,
    params?: Record<string, number | string>,
  ) => string;

  function trOptional(key: string, params?: Record<string, number | string>) {
    const translation = tr(key, params);
    return translation !== key ? translation : undefined;
  }

  return { ...omit(globalI18n, ["t"]), tr, trOptional };
}

export function defineI18n<
  Locale extends LocaleIso | LocaleMorpheus,
  MessageSchema,
  Translations = MessageSchemaToTranslations<MessageSchema>,
>(
  loader: (locale: Locale) => MessageSchema | Promise<MessageSchema>,
): () => SI18nInstance<MessageSchema, Locale, Translations> {
  const loadForLocale = memoize(async (locale: Locale) => {
    i18nInstance().global.mergeLocaleMessage(locale, await loader(locale));
  });

  return (): SI18nInstance<MessageSchema, Locale, Translations> => {
    const i18n = useI18n<MessageSchema, Locale, Translations>();
    void loadForLocale(i18n.locale.value);
    return i18n;
  };
}

/*
  Auto translation structure:
  fields: {
    fieldName: {
      label: string,
      tooltip: string,
      description: string,
      placeholder: string,
      subtext: string,
      errorLabel: string,
      validation: {
        [ruleName: string]: string,
      }
    }
  }
*/

function useAutoI18n(
  name: Readonly<Ref<string>>,
  props: Partial<{
    description: string | null;
    errorLabel: string | null;
    label: string | null;
    placeholder: string | null;
    subtext: string | null;
    tooltip: string | null;
  }>,
) {
  const { trOptional } = useI18n();

  const label = computed(
    () => props.label ?? trOptional(`fields.${name.value}.label`) ?? name.value,
  );

  const tooltip = computed(
    () => props.tooltip ?? trOptional(`fields.${name.value}.tooltip`),
  );

  const description = computed(
    () => props.description ?? trOptional(`fields.${name.value}.description`),
  );

  const placeholder = computed(
    () => props.placeholder ?? trOptional(`fields.${name.value}.placeholder`),
  );

  const subtext = computed(
    () => props.subtext ?? trOptional(`fields.${name.value}.subtext`),
  );

  const errorLabel = computed(
    () =>
      props.errorLabel ??
      trOptional(`fields.${name.value}.errorLabel`) ??
      label.value,
  );

  return {
    label,
    tooltip,
    description,
    placeholder,
    subtext,
    errorLabel,
  };
}

export { i18nInstance, useAutoI18n, useI18n };
export type { SI18nInstance };
