import { computedWithControl } from "@vueuse/core";
import { nanoid } from "nanoid";
import { isObject, shake, sort } from "radash";
import { type DeepReadonly, type MaybeRef, type Ref, watchEffect } from "vue";
import { computed, ref, toValue, watch } from "vue";

import type {
  OptionItem,
  OptionItemProp,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

import { bestMatch } from "@/lib/helpers/stringSimilarity";
import { arrayWrap } from "@/lib/helpers/utils";
import { memoize } from "@/lib/helpers/utils/memoize.ts";

type OptionItemNormalized = Omit<OptionItem, "children"> & {
  children?: readonly OptionValue[];
};

function useOptionsStoreItems(
  items: DeepReadonly<
    Ref<OptionItemProp[] | Record<number | string, string> | string[]>
  >,
  modelValue: DeepReadonly<Ref<OptionValue[]>>,
  name: Readonly<Ref<string>>,
  multiple: Readonly<Ref<boolean>>,
  required: Readonly<Ref<boolean>>,
  disabled: Readonly<Ref<boolean>>,
) {
  type OptionItemMeta = {
    id: string;
    isOpen: boolean;
  };

  const activeItemValue = ref<OptionValue | null>(null);

  const itemsMeta = ref(new Map<OptionValue, OptionItemMeta>());
  function setItemMeta(value: OptionValue, itemMeta: Partial<OptionItemMeta>) {
    itemsMeta.value.set(value, { ...itemsMeta.value.get(value)!, ...itemMeta });
  }
  function initItemMeta(item: DeepReadonly<OptionItemProp> | OptionValue) {
    if (!isObject(item)) {
      if (!itemsMeta.value.has(item)) {
        setItemMeta(item, { id: nanoid(10), isOpen: true });
      }
      return;
    }

    if (!itemsMeta.value.has(item.value)) {
      setItemMeta(item.value, { id: nanoid(10), isOpen: item.isOpen ?? true });
    }
    if (item.children) {
      initItemsMeta(item.children);
    }
  }
  function initItemsMeta(
    items: DeepReadonly<
      OptionItemProp[] | Record<number | string, string> | string[]
    >,
  ) {
    if (Array.isArray(items)) {
      items.forEach(initItemMeta);
    }
    Object.keys(items).forEach(initItemMeta);
  }

  watchEffect(() => initItemsMeta(items.value));

  const mappedItems = computed<DeepReadonly<OptionItem[]>>(() => {
    return mapItems(items.value);
  });

  function mapItems(
    items: DeepReadonly<
      OptionItemProp[] | Record<number | string, string> | string[]
    >,
  ) {
    if (Array.isArray(items)) {
      return items.map((item: OptionItemProp | string) => fillItem(item));
    }

    return Object.entries(items).map(([key, value]) => {
      return fillItem({
        value: Number.isNaN(Number(key)) ? key : Number(key),
        label: String(value),
      });
    });
  }

  function fillItem(
    item: DeepReadonly<OptionItemProp> | OptionValue,
    itemModelValue?: boolean,
  ): OptionItem {
    if (!isObject(item)) {
      item = { value: item, label: item.toString() };
    }
    return shake({
      name: name.value,
      ...item,
      ...itemsMeta.value.get(item.value)!,
      isActive: activeItemValue.value === item.value,
      isParent: !!item.children?.length,
      modelValue: itemModelValue ?? modelValue.value.includes(item.value),
      required: item.required || (required.value && !multiple.value),
      type: multiple.value ? "checkbox" : "radio",
      disabled: item.disabled || disabled.value,
      children: item.children ? mapItems(item.children) : undefined,
    }) as OptionItem;
  }

  const normalizeItem = memoize(
    (item: DeepReadonly<OptionItem>): DeepReadonly<OptionItemNormalized[]> => {
      if (!item.children) {
        return [item as OptionItemNormalized];
      }
      return [
        { ...item, children: item.children.map(({ value }) => value) },
        ...item.children.map(normalizeItem).flat(),
      ];
    },
  );

  const normalizedItems = computed(() => {
    return mappedItems.value.reduce<DeepReadonly<OptionItemNormalized[]>>(
      (normalized, item) => {
        return normalized.concat(normalizeItem(item));
      },
      [],
    );
  });

  // This kind of makes checkedItems as computed as it updates when it's dependencies change.
  // It's not a computed because it needs to retain some of its previous state
  const checkedItems = ref<DeepReadonly<OptionItemNormalized[]>>([]);
  function syncCheckedItems() {
    checkedItems.value = modelValue.value.reduce<OptionItemNormalized[]>(
      (items, value) => {
        const item =
          findItem(value, normalizedItems) ?? findItem(value, checkedItems);
        if (!item) {
          return items;
        }
        return items.concat({ ...item, modelValue: true });
      },
      [],
    );
  }
  watch([modelValue, normalizedItems], syncCheckedItems, {
    immediate: true,
    flush: "sync",
  });

  // Values that are present in modelValue but were never present in mappedItems are computed into full items,
  // so we can display them correctly.
  const typedItems = computedWithControl(checkedItems, () => {
    return arrayWrap(modelValue.value)
      .filter((value) => !findItem(value, checkedItems))
      .map((value) => fillItem(value, true) as OptionItemNormalized);
  });

  const allItems = computedWithControl(
    checkedItems,
    (): Readonly<OptionItemNormalized>[] => {
      return sort([...checkedItems.value, ...typedItems.value], ({ value }) =>
        modelValue.value.indexOf(value),
      );
    },
  );

  function findItem<Item extends OptionItem | OptionItemNormalized>(
    value: OptionValue,
    store: DeepReadonly<MaybeRef<Item[]>>,
  ) {
    return (
      toValue(store).find((storedItem) => storedItem.value === value) ?? null
    );
  }

  function findItemIndex<Item extends OptionItem | OptionItemNormalized>(
    value: OptionValue,
    store: DeepReadonly<MaybeRef<Item[]>>,
  ) {
    const index = toValue(store).findIndex(
      (storedItem) => storedItem.value === value,
    );
    return index === -1 ? null : index;
  }

  function matchItemByLabel(label: string, threshold = 1) {
    const match = bestMatch(
      label,
      mappedItems.value.map(({ label }) => label),
      { threshold, caseSensitive: false },
    );

    if (!match) {
      return null;
    }

    return mappedItems.value.find((item) => item.label === match) ?? null;
  }

  function setIsOpen(value: OptionValue, isOpen: boolean) {
    setItemMeta(value, { isOpen });
  }

  return {
    setIsOpen,
    activeItemValue,
    findItem,
    findItemIndex,
    matchItemByLabel,
    normalizedItems,
    mappedItems,
    allItems,
    checkedItems,
    typedItems,
  };
}

export { useOptionsStoreItems };
