<template>
  <SInputWrapperAtom
    v-bind="inputWrapperAtom.props"
    :class="props.class"
    v-on="inputWrapperAtom.on"
  >
    <SInputIconAtom
      v-bind="inputIconAtom.props"
      location="prefix"
      v-on="inputIconAtom.on"
    />
    <SInputFieldAtom
      v-bind="{ ...inputFieldAtom.props, ...$attrs }"
      v-on="inputFieldAtom.on"
    />
    <slot name="suffixIcon">
      <SInputIconAtom
        v-bind="inputIconAtom.props"
        location="suffix"
        v-on="inputIconAtom.on"
      />
    </slot>
  </SInputWrapperAtom>
</template>

<script setup lang="ts">
import type { UseInputAtomEmit } from "@/lib/components/logic/atoms/input/useInputAtom";

import SInputFieldAtom from "@/lib/components/atoms/input/SInputFieldAtom.vue";
import SInputIconAtom from "@/lib/components/atoms/input/SInputIconAtom.vue";
import SInputWrapperAtom from "@/lib/components/atoms/input/wrapper/SInputWrapperAtom.vue";
import * as useInputAtom from "@/lib/components/logic/atoms/input/useInputAtom";
defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  ...useInputAtom.props,
  class: { type: String, default: undefined, required: false },
});

const emit = defineEmits<UseInputAtomEmit>();

const { inputWrapperAtom, inputFieldAtom, inputIconAtom } = useInputAtom.use(
  props,
  emit,
);
</script>
