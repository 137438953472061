<template>
  <STextInput v-bind="textInput.props" v-on="textInput.on">
    <template v-for="(_index, name) in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
    <template #suggestion="{ params: { suggestion, apply } }">
      <SAlert class="s-email__suggestion" color="gray">
        {{ $t("fields.email.didYouMean") }}
        <SButton
          action-type="quaternary"
          class="s-email__suggestion-button"
          size="inline"
          @click="apply"
        >
          {{ suggestion }}
        </SButton>
      </SAlert>
    </template>
  </STextInput>
</template>

<script setup lang="ts">
import type { UseEmailEmit } from "@/lib/components/logic/molecules/useEmail";

import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import SButton from "@/lib/components/atoms/button/SButton.vue";
import * as useEmail from "@/lib/components/logic/molecules/useEmail";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";

const props = defineProps(useEmail.props);
const emit = defineEmits<UseEmailEmit>();

const { textInput } = useEmail.use(props, emit);
</script>

<style lang="postcss">
.s-email__suggestion {
  @apply mt-2;

  &-button {
    @apply ml-0;
  }
}
</style>
