<template>
  <div
    v-if="label"
    class="s-label-atom"
    :class="{
      [`s-label-atom--size-${size}`]: true,
    }"
  >
    <component
      :is="tag"
      v-bind="wrapper.props"
      class="s-label-atom__text"
      :class="{
        's-label-atom__text--disabled': disabled,
        [`s-label-atom__text--size-${size}`]: true,
      }"
    >
      <slot>{{ label }}</slot>
      <span v-if="suffix" class="s-label-atom__suffix">{{ suffix }}</span>
    </component>
    <STooltipInfo v-bind="tooltip.props" />
  </div>
</template>

<script setup lang="ts">
import STooltipInfo from "@/lib/components/atoms/tooltip/STooltipInfo.vue";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import { pickProps } from "@/lib/composables/componentComposable";

const props = defineProps({ ...useLabel.props, ...useTooltip.props });

const tooltip = { props: pickProps(props, useTooltip.props) };

const { wrapper, label, tag, disabled, suffix, size } = useLabel.use(props);
</script>

<style lang="postcss">
.s-label-atom {
  @apply flex gap-x-2;

  &__text {
    @apply mb-0 font-medium text-gray-900;

    &--disabled {
      @apply text-gray-600;

      .s-label-atom__suffix {
        @apply text-gray-500;
      }
    }

    &--size {
      &-lg {
        @apply text-lg;
      }

      &-md {
        @apply text-base;
      }

      &-sm {
        @apply text-sm;
      }
    }
  }

  &__suffix {
    @apply ml-2 text-gray-700;
  }

  &--size {
    &-lg {
      @apply mb-2;
    }

    &-md {
      @apply mb-2;
    }

    &-sm {
      @apply mb-1;
    }
  }
}
</style>
