import { toValue } from "vue";

import type { EventsCallback } from "./validation.types";

const aggressive = ["input", "update:modelValue", "blur"];

const eager: EventsCallback<unknown> = (error) => {
  return error
    ? ["input", "update:modelValue", "change", "blur"]
    : ["change", "blur"];
};

const onCreatedIfValue = <ModelValue>(
  otherEvents: EventsCallback<ModelValue> | string[],
  watchValue?: unknown,
) => {
  return (error: string | null, value: ModelValue) => {
    const events = Array.isArray(otherEvents)
      ? otherEvents
      : otherEvents(error, value);

    if (toValue(watchValue) || (toValue(watchValue) === undefined && value)) {
      return [...events, "created"];
    }
    return events;
  };
};

export { aggressive, eager, onCreatedIfValue };
