<template>
  <div class="s-option-image-group">
    <SCheckboxRadioImage
      v-for="item in items"
      :key="item.key"
      v-bind="item.props"
      v-on="item.on"
    />
  </div>
</template>

<script setup lang="ts">
import type { UseCheckboxRadioGroupEmit } from "@/lib/components/logic/atoms/useCheckboxRadioGroup";

import * as useCheckboxRadioGroup from "@/lib/components/logic/atoms/useCheckboxRadioGroup";

import SCheckboxRadioImage from "../checkbox-radio/SCheckboxRadioImage.vue";

const props = defineProps(useCheckboxRadioGroup.props);
const emit = defineEmits<UseCheckboxRadioGroupEmit>();

const { items } = useCheckboxRadioGroup.use(props, emit);
</script>

<style lang="postcss">
.s-option-image-group {
  @apply grid gap-2 grid-cols-auto-grow-48;
}
</style>
