<template>
  <div class="s-form-submit-error">
    <div class="s-form-submit-error__title">{{ $t("error.title") }}</div>
    <p class="s-form-submit-error__text" v-html="$t('error.text')" />
  </div>
</template>

<style lang="postcss">
.s-form-submit-error {
  @apply rounded border border-danger-200 bg-danger-100 p-8;

  &__title {
    @apply mb-4 font-secondary text-2xl font-bold text-danger-600;
  }

  &__text {
    @apply text-gray-800;
  }
}
</style>
