<template>
  <fieldset class="s-button-group" v-bind="container.props">
    <SLabelAtom v-bind="{ ...labelAtom.props, ...tooltipAtom.props }" />
    <SDescription
      v-if="descriptionAtom.if.value"
      v-bind="descriptionAtom.props"
    />
    <slot name="checkboxRadioGroup" v-bind="checkboxRadioGroup">
      <SCheckboxRadioGroup
        v-bind="checkboxRadioGroup.props"
        v-on="checkboxRadioGroup.on"
      >
        <template v-for="(_index, name) in $slots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </SCheckboxRadioGroup>
    </slot>
    <SSubtext v-if="subtextAtom.if.value" v-bind="subtextAtom.props" />
    <slot v-if="error" :name="errorComponent?.toString()" v-bind="errorProps">
      <SErrorAtom v-bind="errorProps" />
    </slot>
  </fieldset>
</template>

<script setup lang="ts">
import type { UseButtonGroupEmit } from "@/lib/components/logic/molecules/useButtonGroup";

import SDescription from "@/lib/components/atoms/alert/SDescription.vue";
import SCheckboxRadioGroup from "@/lib/components/atoms/checkbox-radio-group/SCheckboxRadioGroup.vue";
import SErrorAtom from "@/lib/components/atoms/error/SErrorAtom.vue";
import SLabelAtom from "@/lib/components/atoms/label/SLabelAtom.vue";
import SSubtext from "@/lib/components/atoms/subtext/SSubtext.vue";
import * as useButtonGroup from "@/lib/components/logic/molecules/useButtonGroup";

const props = defineProps(useButtonGroup.props);
const emit = defineEmits<UseButtonGroupEmit>();

defineSlots<{
  checkboxRadioGroup: (component: typeof checkboxRadioGroup) => never;
  // eslint-disable-next-line
  [key: string]: (data: any) => never;
}>();

const {
  container,
  labelAtom,
  tooltipAtom,
  descriptionAtom,
  checkboxRadioGroup,
  subtextAtom,
  error,
  errorComponent,
  errorProps,
} = useButtonGroup.use(props, emit);
</script>
