import type { ComponentPublicInstance, Ref } from "vue";

import { ref } from "vue";

export type TemplateRef = (
  el: ComponentPublicInstance | Element | null,
) => void;

export function useTemplateRef<
  ElementType extends HTMLElement = HTMLElement,
>() {
  const element: Ref<ElementType | null> = ref(null);

  // https://vuejs.org/guide/essentials/template-refs.html#function-refs
  const bindElement = (el: ComponentPublicInstance | Element | null) => {
    // @ts-expect-error Using some vue internals
    element.value = el && "$el" in el ? (el.$el as ElementType) : el;
  };

  return {
    ref: bindElement,
    el: element,
  };
}
