<template>
  <SAlert
    v-if="featureFlagStore.reviewStars"
    class="s-form-review-stars"
    color="primary"
  >
    <div class="s-form-review-stars__content">
      <SReviewScoreAtom
        :icon="faCircleStar"
        :model-value="4.5"
        :show-number-score="false"
      />
      <span>
        {{ $t("reviewStars.description") }}
      </span>
    </div>
  </SAlert>
</template>

<script setup lang="ts">
import { faCircleStar } from "@fortawesome/pro-solid-svg-icons";

import { SAlert, SReviewScoreAtom } from "@solvari/common-fe";

import { useFeatureFlagStore } from "@/plugins/store/featureFlags";
const featureFlagStore = useFeatureFlagStore();
</script>

<style lang="postcss">
.s-form-review-stars {
  @apply w-fit;

  &__content {
    @apply flex flex-col gap-3;
    @screen 500 {
      @apply flex-row;
    }
  }
}
</style>
