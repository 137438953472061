<template>
  <div v-if="subtext" :id="id" class="s-subtext">{{ subtext }}</div>
</template>

<script setup lang="ts">
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
const props = defineProps(useSubtext.props);

const { id, subtext } = useSubtext.use(props);
</script>

<style lang="postcss">
.s-subtext {
  @apply mt-1 text-sm text-gray-700;
}
</style>
