<template>
  <div
    class="s-address--fr-fr"
    :class="[`s-address--fr-fr--size-${props.size}`]"
  >
    <STextInput
      v-model="zipcodeModel"
      autocomplete="postal-code"
      class="s-address--fr-fr__zipcode"
      :maxlength="5"
      name="zipcode"
      required
      :rules="[localeSwitchRule(), patternRule(zipcodeRegex['fr-FR'])]"
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    >
      <template #localeSwitch>
        <SAlert
          class="s-address--fr-fr__locale-switch"
          color="primary"
          :prefix-icon="faCircleExclamation"
          :size="size"
        >
          {{ $t("address.localeWarning.text") }} <br />
          <SButton
            action-type="link"
            size="inline"
            :suffix-icon="faChevronRight"
            @click="switchLocale"
          >
            {{ $t("address.localeWarning.button") }}
          </SButton>
        </SAlert>
      </template>
    </STextInput>

    <STextInput
      v-model="cityModel"
      autocomplete="address-level2"
      class="s-address--fr-fr__city"
      :maxlength="255"
      name="city"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="streetModel"
      autocomplete="address-level1"
      class="s-address--fr-fr__street"
      :maxlength="255"
      name="street"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumberModel"
      autocomplete="address-level2"
      class="s-address--fr-fr__house-number"
      :maxlength="255"
      name="houseNumber"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import {
  faChevronRight,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

import type { LocaleIso } from "@/lib/helpers/locales";

import SAlert from "@/lib/components/atoms/alert/SAlert.vue";
import SButton from "@/lib/components/atoms/button/SButton.vue";
import { size as sizeProp } from "@/lib/components/logic/atoms/input/props";
import STextInput from "@/lib/components/molecules/text-input/STextInput.vue";
import { useLocaleSwitch } from "@/lib/components/organisms/address/useLocaleSwitch";
import { reEmit } from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";
import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { eager, onCreatedIfValue } from "@/lib/validation/events";
import { patternRule } from "@/lib/validation/rules";

const props = defineProps({
  availableLocales: {
    type: Array as PropType<readonly LocaleIso[]>,
    default: () => [],
  },
  locale: { type: String as PropType<LocaleIso>, required: true },
  size: sizeProp,
  zipcode: { type: String as PropType<string | null>, default: "" },
  houseNumber: { type: String as PropType<string | null>, default: "" },
  street: { type: String as PropType<string | null>, default: "" },
  city: { type: String as PropType<string | null>, default: "" },
});

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string | null): void;
  (event: "update:houseNumber", houseNumber: string | null): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string | null): void;
  (event: "update:zipcode", zipcode: string | null): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit);
const houseNumberModel = useModel("houseNumber", props, emit);
const cityModel = useModel("city", props, emit);
const streetModel = useModel("street", props, emit);

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);
</script>

<style lang="postcss">
.s-address--fr-fr {
  @apply grid;
  grid-template-areas:
    "zipcode city city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &--size {
    &-sm {
      @apply gap-3;
    }

    &-md {
      @apply gap-4;
    }

    &-lg {
      @apply gap-5;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }
}
</style>
