<template>
  <div class="form-question">
    <SEmail
      v-model="email"
      :locale="localeIso"
      name="email"
      required
      show-required-type="none"
      v-on="reEmit(emit, ['focus', 'validationError'])"
    />
    <SCheckbox
      v-model="newsletter"
      name="newsletter"
      v-on="reEmit(emit, ['focus', 'validationError'])"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@solvari/common-fe/composables";
import { type LocaleIso, reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "@vueuse/core";

import { SCheckbox, SEmail } from "@solvari/common-fe";

import { useLeadStore } from "@/plugins/store/lead";

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const { locale } = useI18n();
const localeIso = toRef(() => locale.value as LocaleIso);

const leadStore = useLeadStore();
const email = toRef(leadStore, "email");
const newsletter = toRef(leadStore, "newsletter");
</script>
