import {
  storeSolvariClientId,
  storeSolvariSessionUuid,
} from "@/lib/argus/service/service";

import { emitWindowMessage, onWindowMessage } from "./windowMessage";

function emitComponentLoaded(component: string) {
  emitWindowMessage("loaded", { component });
}

function initEmitIframeResize() {
  const observer = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      emitWindowMessage("resize", { height: document.body.scrollHeight });
    });
  });
  observer.observe(document.body);
}

function emitWindowRedirect(url: string) {
  emitWindowMessage("redirect", { url });
}

function emitIframeScroll(position: number) {
  emitWindowMessage("scroll", { position });
}

function emitScrollIntoView(element: HTMLElement) {
  const position = element.getBoundingClientRect().top;
  emitIframeScroll(position);
}

function initOnPrefill(fields: {
  city: string | null;
  houseNumber: string | null;
  street: string | null;
  zipcode: string | null;
}) {
  onWindowMessage("prefill", (prefill) => {
    if (prefill.zipcode) {
      fields.zipcode = prefill.zipcode;
    }
    if (prefill.houseNumber) {
      fields.houseNumber = prefill.houseNumber;
    }
    if (prefill.city) {
      fields.city = prefill.city;
    }
    if (prefill.street) {
      fields.street = prefill.street;
    }
  });
}
function initOnArgusSession() {
  onWindowMessage("argus", ({ session, client }) => {
    if (session) {
      storeSolvariSessionUuid(session);
    }
    if (client) {
      storeSolvariClientId(client);
    }
  });
}

export {
  emitComponentLoaded,
  emitIframeScroll,
  emitScrollIntoView,
  emitWindowRedirect,
  initEmitIframeResize,
  initOnArgusSession,
  initOnPrefill,
};
