/*
  The thin client only has basic functionality like getting the events and uuid's.
  This prevents having to include extra dependencies that are only needed for actual backend communication.
  This is used by the partner iframes.
 */

import { getCookie } from "@/lib/helpers/cookies";
import { sSessionStorage } from "@/lib/helpers/localStorage";

type SessionType = "cross-domain" | "new" | "same-domain";
function getSessionType(): SessionType {
  if (getArgusEvents()?.length) {
    return "same-domain";
  }

  if (new URLSearchParams(window.location.search).get("solvariSession")) {
    return "cross-domain";
  }

  return "new";
}

function getArgusEvents() {
  return sSessionStorage.getItem("argusEvents");
}

function getSessionUuid() {
  return sSessionStorage.getItem("solvariSession");
}

function getSolvariClientId() {
  return getCookie("solvariClientId", false);
}

function applyArgusUuidToUrl(
  link: string,
  sessionUuid = getSessionUuid(),
  solvariClientId = getSolvariClientId(),
) {
  try {
    const url = new URL(link);
    if (url.protocol !== "http:" && url.protocol !== "https:") {
      return link;
    }
  } catch (_error) {
    return link;
  }

  const url = new URL(link);
  if (url.hostname === window.location.hostname) {
    return link;
  }

  if (sessionUuid) {
    url.searchParams.set("solvariSession", sessionUuid);
  }
  if (solvariClientId) {
    url.searchParams.set("solvariClientId", solvariClientId);
  }

  return url.href;
}

export {
  applyArgusUuidToUrl,
  getArgusEvents,
  getSessionType,
  getSessionUuid,
  getSolvariClientId,
};
export type { SessionType };
