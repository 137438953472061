<template>
  <div class="s-form-error">
    <div class="s-form-error__title">{{ $t("loadingError.title") }}</div>
    <p class="s-form-error__text" v-html="$t('loadingError.text')" />
  </div>
</template>

<style lang="postcss">
.s-form-error {
  @apply mb-6 rounded border border-danger-200 bg-danger-100 p-8;

  &__title {
    @apply mb-4 font-secondary text-2xl font-bold text-danger-600;
  }

  &__text {
    @apply text-gray-800;
  }
}
</style>
