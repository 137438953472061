<template>
  <div class="s-form-buttons">
    <SButton
      class="s-form-buttons--next"
      color="accent"
      :loading="applicationStore.submitPending"
      :suffix-icon="faArrowRight"
      type="submit"
    >
      {{ nextLabel }}
    </SButton>
  </div>
</template>

<script setup lang="ts">
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { useI18n } from "@solvari/common-fe/composables";
import { computed } from "vue";

import { SButton } from "@solvari/common-fe";

import { useApplicationStore } from "@/plugins/store/application";
import { useStepperStore } from "@/plugins/store/stepper";

const applicationStore = useApplicationStore();
const stepperStore = useStepperStore();
const { tr } = useI18n();

const nextLabel = computed(() => {
  return stepperStore.nextStep ? tr("actions.next") : tr("actions.submit");
});
</script>

<style lang="postcss">
.s-form-buttons {
  @apply flex flex-row-reverse;

  &--next {
    @apply ml-auto w-full;

    @screen 600 {
      @apply w-auto;
    }
  }
}
</style>
