import type { UseAutocompleteEmit } from "@/lib/components/logic/molecules/useAutocomplete";
import type { DefineProps } from "@/lib/composables/componentComposable";

import * as useAutocomplete from "@/lib/components/logic/molecules/useAutocomplete";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";

const props = propsDefinition({
  ...useAutocomplete.props,
  clearFilterOnSelect: { type: Boolean, default: true },
  allowTypedItems: { type: Boolean, default: true },
  firstItemAlwaysActive: { type: Boolean, default: false },
});

const emits = emitsDefinition(useAutocomplete.emits);

type UseComboboxPresetProps = DefineProps<typeof props>;
type UseComboboxPresetEmit = UseAutocompleteEmit;

function use(props: UseComboboxPresetProps, emit: UseComboboxPresetEmit) {
  return { autocomplete: { props, on: reEmit(emit, emits) } };
}

export type { UseComboboxPresetEmit, UseComboboxPresetProps };
export { emits, props, use };
