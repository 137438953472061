function memoize<ArgsType extends unknown[], Result>(
  callback: (...args: ArgsType) => Result,
): (...args: ArgsType) => Result {
  const cache = new Map<string, Result>();

  return (...args: ArgsType) => {
    const cacheKey = JSON.stringify(args);
    const cachedResult = cache.get(cacheKey);
    if (cachedResult) {
      return cachedResult;
    }

    const result = callback(...args);
    cache.set(cacheKey, result);

    if (result instanceof Promise) {
      result
        .then((value) => {
          if (value instanceof Error) {
            cache.delete(cacheKey);
          }
        })
        .catch((error: unknown) => {
          cache.delete(cacheKey);
          throw error;
        });
    }

    return result;
  };
}

function singleton<Result>(callback: () => Result): {
  (): Result;
  reset: () => void;
} {
  let instance: Result | null = null;

  const wrapped = () => {
    if (instance) {
      return instance;
    }

    const result = callback();
    instance = result;

    if (result instanceof Promise) {
      result
        .then((value) => {
          if (value instanceof Error) {
            instance = null;
          }
        })
        .catch((error: unknown) => {
          instance = null;
          throw error;
        });
    }

    return instance;
  };

  wrapped.reset = () => {
    instance = null;
  };

  return wrapped;
}

export { memoize, singleton };
