<template>
  <component
    :is="getActiveComponent(component.type)"
    v-model="modelValue"
    :question="component"
    v-bind="$attrs"
    v-on="gtmListeners"
  />

  <template v-if="childComponents.length">
    <FormComponent
      v-for="childComponent of childComponents"
      :key="childComponent.id"
      :component="childComponent"
    />
  </template>
</template>

<script setup lang="ts">
import { toRef } from "@vueuse/core";
import { computed } from "vue";

import type { FormComponentAll } from "@/helpers/types";

import FormCheckbox from "@/components/input/FormCheckbox.vue";
import FormCheckboxImage from "@/components/input/FormCheckboxImage.vue";
import FormFile from "@/components/input/FormFile.vue";
import FormNumber from "@/components/input/FormNumber.vue";
import FormRadio from "@/components/input/FormRadio.vue";
import FormRadioImage from "@/components/input/FormRadioImage.vue";
import FormSelect from "@/components/input/FormSelect.vue";
import FormText from "@/components/input/FormText.vue";
import FormDescription from "@/components/input/preset/FormDescription.vue";
import FormEmail from "@/components/input/preset/FormEmail.vue";
import FormFirstName from "@/components/input/preset/FormFirstName.vue";
import FormGender from "@/components/input/preset/FormGender.vue";
import FormLastName from "@/components/input/preset/FormLastName.vue";
import FormPhone from "@/components/input/preset/FormPhone.vue";
import FormZipcode from "@/components/input/preset/FormZipcode.vue";
import FormHeader from "@/components/meta/FormHeader.vue";
import FormHtmlBlock from "@/components/meta/FormHtmlBlock.vue";
import { useChildComponents } from "@/composables/useChildComponents";
import { useGtmListeners } from "@/composables/useGtmListeners.ts";
import { setDefaultAdditional } from "@/helpers/defaultAdditional";
import { useLeadStore } from "@/plugins/store/lead";

const props = defineProps<{ component: FormComponentAll }>();

const leadStore = useLeadStore();

const modelValue = computed({
  get: () => leadStore.additional[props.component.id],
  set: (value) =>
    leadStore.setAdditional({ question: props.component, value: value! }),
});

setDefaultAdditional(props.component, modelValue, leadStore);

const gtmListeners = useGtmListeners(toRef(() => props.component.label));

const childComponents = useChildComponents(props.component, modelValue);

const getActiveComponent = (type: FormComponentAll["type"]) => {
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    FormCheckbox,
    FormCheckboxImage,
    FormDescription,
    FormEmail,
    FormFile,
    FormFirstName,
    FormGender,
    FormHeader,
    FormHtmlBlock,
    FormLastName,
    FormNumber,
    FormPhone,
    FormRadio,
    FormRadioImage,
    FormSelect,
    FormText,
    FormZipcode,
    /* eslint-enable @typescript-eslint/naming-convention */
  }[type];
};
</script>

<style lang="postcss">
.form-question {
  @apply mb-8;
  scroll-margin-top: 2rem;
}
</style>
