import type { Ref } from "vue";

import dayjs, { isDayjs } from "dayjs";
import { clone as baseClone } from "radash";
import { computed, ref, watch } from "vue";

import { isEqual as baseIsEqual } from "@/lib/helpers/utils/isEqual";

// Mimics https://github.com/vuejs/rfcs/discussions/503
function useModel<
  Props extends Record<string, unknown>,
  Name extends string & keyof Props,
>(
  name: Name,
  props: Props,
  emit: (event: `update:${NoInfer<Name>}`, value: NoInfer<Props[Name]>) => void,
  {
    local = false,
    clone = baseClone,
    isEqual = baseIsEqual,
  }: {
    clone?: typeof baseClone;
    isEqual?: typeof baseIsEqual;
    local?: boolean;
  } = {},
): Ref<Props[Name]> {
  const propComputed = computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });

  if (!local) {
    return propComputed;
  }

  const modelRef = ref(clone(propComputed.value)) as Ref<Props[Name]>;

  watch(
    modelRef,
    (value) => {
      if (!isEqual(propComputed.value, value)) {
        propComputed.value = clone(value);
      }
    },
    { deep: true, flush: "sync" },
  );
  watch(
    propComputed,
    (value) => {
      if (!isEqual(modelRef.value, value)) {
        modelRef.value = clone(value);
      }
    },
    { deep: true, flush: "sync" },
  );

  return modelRef;
}

// Same as useModel but also supports dayjs objects
const useDayjsModel: typeof useModel = (name, props, emit, options) => {
  return useModel(name, props, emit, {
    clone(value) {
      return isDayjs(value) ? (dayjs(value) as typeof value) : baseClone(value);
    },
    isEqual(value1, value2) {
      if (isDayjs(value1) && isDayjs(value2)) {
        return value1.isSame(value2);
      }
      if (isDayjs(value1) || isDayjs(value2)) {
        return false;
      }
      return baseIsEqual(value1, value2);
    },
    ...options,
  });
};

export { useDayjsModel, useModel };
