import type { LocaleIso } from "@solvari/common-fe/helpers";

import {
  createArgusEvent,
  createGtmEvent,
} from "@solvari/common-fe/argus/thick-client";
import { defineStore } from "pinia";
import { objectToSnake } from "ts-case-convert";

import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";
import { useLeadStore } from "@/plugins/store/lead";
import { useMetaStore } from "@/plugins/store/meta";
import { useStepperStore } from "@/plugins/store/stepper";

/*
  Stores data about google tag manager datalayer events
 */

export const useGtmStore = defineStore("gtm", {
  state: () => ({
    visitedSteps: [] as { formId: number; index: number }[],
    fieldFocusCounts: {} as Record<string, number>,
  }),
  getters: {
    visitedStepIndexes({ visitedSteps }) {
      const formStore = useFormStore();

      return visitedSteps
        .filter((step) => step.formId === formStore.formId)
        .map(({ index }) => index);
    },
    gtmStepIndex: () => useStepperStore().currentStep + 1,
  },
  actions: {
    setFieldFocused(fieldName: string) {
      if (this.fieldFocusCounts[fieldName]) {
        this.fieldFocusCounts[fieldName]++;
      } else {
        this.fieldFocusCounts[fieldName] = 1;
      }
    },
    setStepVisited() {
      const formStore = useFormStore();
      this.visitedSteps.push({
        index: this.gtmStepIndex,
        formId: formStore.formId,
      });
    },
    formViewEvent() {
      // Triggered on first view of each step per form

      if (
        !useMetaStore().isVisible ||
        !this.gtmStepIndex ||
        this.visitedStepIndexes.includes(this.gtmStepIndex)
      ) {
        return;
      }
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      this.setStepVisited();

      return createGtmEvent(
        "form_view",
        objectToSnake({
          formId: formStore.formId,
          locale: formStore.locale,
          formTitle: formStore.name,
          productId: leadStore.productId,
          formStep: this.gtmStepIndex,
        }),
      );
    },
    generateLeadEvent({ isFirstApplication }: { isFirstApplication: boolean }) {
      const applicationStore = useApplicationStore();
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return Promise.allSettled([
        createArgusEvent("generate_lead", { leadId: applicationStore.uuid }),
        createGtmEvent(
          "generate_lead",
          objectToSnake({
            formId: formStore.formId,
            locale: formStore.locale,
            formTitle: formStore.name,
            leadId: applicationStore.uuid,
            productId: leadStore.productId,
            email: leadStore.email,
            firstName: leadStore.firstName,
            lastName: leadStore.lastName,
            phone: leadStore.phone,
            street: leadStore.street,
            city: leadStore.city,
            country: formStore.locale?.substring(3),
            zipcode: leadStore.zipcode,
            newCustomer: isFirstApplication,
            originLeadUuid: applicationStore.originLeadUuid,
            isCrossSell: formStore.isCrossSellForm,
          }),
        ),
      ]);
    },
    formInteractionEvent({ name, label }: { label: string; name: string }) {
      // Triggered every time a field is focused
      this.setFieldFocused(name);
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return createGtmEvent(
        "form_interaction",
        objectToSnake({
          formId: formStore.formId,
          locale: formStore.locale,
          formTitle: formStore.name,
          formStep: this.gtmStepIndex,
          fieldLabel: label,
          fieldStructured: name,
          focusAmount: this.fieldFocusCounts[name],
          productId: leadStore.productId,
        }),
      );
    },
    formErrorEvent({
      name,
      label,
      error,
    }: {
      error: string;
      label: string;
      name: string;
    }) {
      // Triggered whenever a field's error message changes
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return createGtmEvent(
        "form_error",
        objectToSnake({
          formId: formStore.formId,
          locale: formStore.locale,
          formTitle: formStore.name,
          fieldLabel: label,
          fieldStructured: name,
          errorMessage: error,
          productId: leadStore.productId,
        }),
      );
    },
    localeSwitchEvent(locale: LocaleIso) {
      // Triggered when the locale switch is triggered, before the actual switch
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return createGtmEvent(
        "locale_switch",
        objectToSnake({
          formId: formStore.formId,
          locale,
          formTitle: formStore.name,
          productId: leadStore.productId,
        }),
      );
    },
    goToStepEvent({ previousGtmStepIndex }: { previousGtmStepIndex: number }) {
      // Triggered on manual step navigation
      if (!previousGtmStepIndex) {
        return;
      }
      const formStore = useFormStore();
      const leadStore = useLeadStore();

      return createGtmEvent(
        "go_to_step",
        objectToSnake({
          formId: formStore.formId,
          locale: formStore.locale,
          formTitle: formStore.name,
          productId: leadStore.productId,
          formStep: previousGtmStepIndex,
          formStepTarget: this.gtmStepIndex,
        }),
      );
    },
    whatsappOptIn() {
      const leadStore = useLeadStore();
      void createGtmEvent(
        "update_whatsapp_settings",
        objectToSnake({
          whatsappOptIn: leadStore.whatsappAgreement,
          context: "form",
        }),
      );
    },
  },
});
