<template>
  <div
    v-if="shouldShow"
    class="s-character-counter"
    :class="{
      's-character-counter--max': maxReached,
      's-character-counter--disabled': disabled,
    }"
  >
    {{ length }} / {{ maxlength }}
  </div>
</template>

<script setup lang="ts">
import * as useCharacterCounter from "@/lib/components/logic/atoms/useCharacterCounter";

const props = defineProps(useCharacterCounter.props);
const { shouldShow, length, maxlength, maxReached, disabled } =
  useCharacterCounter.use(props);
</script>

<style lang="postcss">
.s-character-counter {
  @apply ml-auto mt-1 inline-block text-sm text-gray-700 transition-text;

  &--max {
    @apply text-danger-500;
  }

  &--disabled {
    @apply text-gray-500;

    &.s-character-counter--max {
      @apply text-danger-400;
    }
  }
}
</style>
