<template>
  <label
    class="s-checkbox-radio"
    :class="{
      's-checkbox-radio--disabled': disabled,
      's-checkbox-radio--checked': modelValue || indeterminate,
      's-checkbox-radio--outlined': outlined,
      [`s-checkbox-radio--size-${size}`]: true,
    }"
  >
    <slot name="checkboxRadioInput" v-bind="inputAtom">
      <SCheckboxRadioInput v-bind="inputAtom.props" v-on="inputAtom.on" />
    </slot>
    <span class="s-checkbox-radio__label" v-html="label" />
    <STooltipInfo v-if="tooltipAtom.props.tooltip" v-bind="tooltipAtom.props" />
    <slot name="suffix" />
  </label>
</template>

<script setup lang="ts">
import type { UseCheckboxRadioEmit } from "@/lib/components/logic/atoms/useCheckboxRadio";

import SCheckboxRadioInput from "@/lib/components/atoms/checkbox-radio/SCheckboxRadioInput.vue";
import STooltipInfo from "@/lib/components/atoms/tooltip/STooltipInfo.vue";
import * as useCheckboxRadio from "@/lib/components/logic/atoms/useCheckboxRadio";

const props = defineProps({
  ...useCheckboxRadio.props,
  outlined: { type: Boolean, default: false },
});
const emit = defineEmits<UseCheckboxRadioEmit>();

defineSlots<{
  "checkboxRadioInput"(props: typeof inputAtom): never;
  "suffix"(): never;
}>();

const {
  inputAtom,
  tooltipAtom,
  label,
  disabled,
  modelValue,
  indeterminate,
  size,
} = useCheckboxRadio.use(props, emit);
</script>

<style lang="postcss">
.s-checkbox-radio {
  @apply relative mb-0 flex cursor-pointer items-center gap-3 font-normal;

  &__label {
    @apply py-1 text-gray-900;
  }

  &--outlined {
    @apply rounded border border-gray-200 bg-white px-3 transition-colors;

    &:hover {
      @apply bg-gray-100;
    }

    &.s-checkbox-radio--checked {
      @apply border-primary-700;
    }
  }

  &--size {
    &-lg {
      @apply min-h-10 text-lg leading-tight;

      .s-checkbox-radio--outlined {
        @apply px-4;
      }
    }

    &-md {
      @apply min-h-9 text-base leading-tight;

      .s-checkbox-radio--outlined {
        @apply px-3;
      }
    }

    &-sm {
      @apply min-h-8 text-sm leading-tight;

      .s-checkbox-radio--outlined {
        @apply px-2.5;
      }
    }
  }

  &--disabled {
    @apply cursor-default;

    &.s-checkbox-radio--outlined {
      @apply bg-gray-50;
    }

    .s-checkbox-radio__label {
      @apply text-gray-600;
    }
  }
}
</style>
