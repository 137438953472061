<template>
  <span
    class="s-input-text-atom"
    :class="{
      [`s-input-text-atom--size-${size}`]: true,
      's-input-text-atom--prefix-icon': hasPrefixIcon,
      's-input-text-atom--suffix-icon': hasSuffixIcon,
      's-input-text-atom--disabled': disabled,
    }"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import * as useInputTextAtom from "@/lib/components/logic/atoms/input/useInputTextAtom";

const props = defineProps(useInputTextAtom.props);

const { size, hasPrefixIcon, hasSuffixIcon, disabled } =
  useInputTextAtom.use(props);
</script>

<style lang="postcss">
.s-input-text-atom {
  @apply inline-flex w-full flex-grow items-center overflow-x-scroll whitespace-nowrap rounded bg-transparent text-gray-800;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--size {
    &-lg {
      @apply px-6 text-lg leading-none;
      height: calc(theme("spacing.14") - 2px);
    }

    &-md {
      @apply px-5 text-base leading-none;
      height: calc(theme("spacing.12") - 2px);
    }

    &-sm {
      @apply px-4 text-base leading-none;
      height: calc(theme("spacing.10") - 2px);
    }
  }

  &--prefix-icon {
    @apply pl-0;
  }

  &--suffix-icon {
    @apply pr-0;
  }

  &--disabled {
    @apply text-gray-600;
  }
}
</style>
