import type { PropType } from "vue";

import { toRef } from "vue";

import type { Color } from "@/lib/components/types";
import type { DefineProps } from "@/lib/composables/componentComposable";

import { propsDefinition } from "@/lib/composables/componentComposable";

const props = propsDefinition({
  error: { type: String as PropType<string | null>, required: false },
  component: { type: String, default: "text" },
  color: { type: String as PropType<Color>, default: "danger" },
});

type UseErrorProps = DefineProps<typeof props>;

function use(props: UseErrorProps) {
  return {
    component: toRef(() => props.component),
    error: toRef(() => props.error),
    color: toRef(() => props.color),
  };
}

export type { UseErrorProps };
export { props, use };
