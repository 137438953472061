<template>
  <span v-if="disabled" class="s-tooltip--disabled">
    <slot name="trigger" />
  </span>
  <div
    v-else
    :ref="triggerRef"
    class="s-tooltip"
    :class="{ 's-tooltip--dark': dark }"
    @click.prevent=""
  >
    <slot name="trigger" />
    <div
      :id="id"
      :ref="popupRef"
      class="s-tooltip__popup"
      :class="{ 's-tooltip__popup--open': isOpen }"
    >
      <slot>{{ tooltip }}</slot>
      <div :ref="arrowRef" class="s-tooltip__arrow" />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";

const props = defineProps(useTooltip.props);
const { triggerRef, popupRef, arrowRef, tooltip, dark, disabled, id, isOpen } =
  useTooltip.use(props);
</script>

<style lang="postcss">
.s-tooltip {
  @apply inline-flex;

  &__popup {
    @apply left-0 top-0 w-max-content max-w-64 rounded bg-white px-2 py-1 text-sm font-normal text-gray-800;
  }

  &__arrow {
    @apply absolute z-10 h-2 w-2 rotate-45 bg-white;
  }

  &__popup {
    @apply invisible absolute z-10 opacity-0;
    transition:
      opacity 200ms 0ms,
      visibility 0ms 200ms;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));

    &--open {
      @apply visible opacity-100 transition-opacity;
    }
  }

  &--dark .s-tooltip {
    &__popup {
      @apply bg-gray-800 text-white;
    }

    &__arrow {
      @apply bg-gray-800;
    }
  }
}
</style>
