<template>
  <STextInput
    v-model="model"
    class="form-question"
    :maxlength="255"
    name="lastName"
    required
    show-required-type="none"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "vue";

import { STextInput } from "@solvari/common-fe";

import { useLeadStore } from "@/plugins/store/lead";
defineOptions({ inheritAttrs: false });

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const leadStore = useLeadStore();
const model = toRef(leadStore, "lastName");
</script>
