import type { Ref } from "vue";

import { intersects, unique } from "radash";
import { computed } from "vue";

import type { InputValue } from "@/lib/helpers/types";

import { arrayWrap } from "@/lib/helpers/utils";

import type { OptionValue } from "./useOptionsStore";

function useOptionsStoreModel(
  rawModel: Ref<InputValue<OptionValue | readonly OptionValue[]>>,
  multiple: Readonly<Ref<boolean>>,
) {
  function hasValue<Value>(
    value: Value,
  ): value is Exclude<Value, null | undefined> {
    return !(["", null, undefined] as unknown[]).includes(value);
  }

  const modelValue = computed(() =>
    hasValue(rawModel.value) ? arrayWrap(rawModel.value) : [],
  );

  function add(optionValue: OptionValue | readonly OptionValue[]) {
    const valuesToAdd = arrayWrap(optionValue);
    if (!valuesToAdd.some((value) => !modelValue.value.includes(value))) {
      return;
    }

    if (multiple.value) {
      rawModel.value = unique([...modelValue.value, ...valuesToAdd]);
    } else {
      rawModel.value = valuesToAdd[0];
    }
  }

  function remove(optionValue: OptionValue | readonly OptionValue[]) {
    const valuesToRemove = arrayWrap(optionValue);
    if (!intersects(modelValue.value, valuesToRemove)) {
      return;
    }

    if (multiple.value) {
      rawModel.value = modelValue.value.filter(
        (modelValueFilter) => !valuesToRemove.includes(modelValueFilter),
      );
    } else {
      rawModel.value = null;
    }
  }

  return { modelValue, add, remove };
}

export { useOptionsStoreModel };
