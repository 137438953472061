import type { InjectionKey, PropType, Ref } from "vue";

import { omit } from "radash";
import { computed, nextTick, provide, ref, watch } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import { propsDefinition } from "@/lib/composables/componentComposable";

type ServerSideErrors = Record<string, string[]>;
type ValidationProvider = {
  invalid: boolean;
  reset: () => void;
  serverSideError?: string;
  valid: boolean;
  validateAll: () => Promise<void>;
};

const _validationObserverKey = Symbol() as InjectionKey<{
  deleteProvider: (name: string) => void;
  resetServerSideErrors: (name: string) => void;
  serverSideErrors: Ref<ServerSideErrors>;
  updateProvider: (name: string, provider: ValidationProvider) => void;
}>;

const useValidationObserverProps = propsDefinition({
  serverSideErrors: {
    type: Object as PropType<ServerSideErrors>,
    default: () => ({}),
  },
});

function useValidationObserver(
  props?: DefineProps<typeof useValidationObserverProps>,
) {
  /*
    Server side errors
   */
  const storedServerSideErrors = ref<ServerSideErrors>({});

  if (props?.serverSideErrors) {
    watch(props.serverSideErrors, () => {
      storedServerSideErrors.value = structuredClone(props.serverSideErrors);
    });
  }

  function resetServerSideErrors(name: string) {
    if (storedServerSideErrors.value[name]) {
      delete storedServerSideErrors.value[name];
    }
  }

  /*
    Providers
   */
  const observedProviders = ref<Record<string, ValidationProvider>>({});

  const observedProvidersValues = computed(() => {
    return Object.values(observedProviders.value);
  });

  function updateProvider(name: string, provider: ValidationProvider) {
    observedProviders.value[name] = provider;
  }

  function deleteProvider(name: string) {
    observedProviders.value = omit(observedProviders.value, [name]);
  }

  function reset() {
    observedProvidersValues.value.forEach((provider) => provider.reset());
  }

  provide(_validationObserverKey, {
    serverSideErrors: storedServerSideErrors,
    updateProvider,
    deleteProvider,
    resetServerSideErrors,
  });

  /*
    Validation
   */
  const valid = computed(() => {
    return observedProvidersValues.value.every((provider) => provider.valid);
  });

  const invalid = computed(() => {
    return observedProvidersValues.value.some((provider) => provider.invalid);
  });

  async function validateAll() {
    const validationPromises = observedProvidersValues.value.map((provider) =>
      provider.validateAll(),
    );
    await Promise.all(validationPromises);
    await nextTick(); // Wait for validation to update in providers
    await nextTick(); // Wait for providers to update their state in the Observer
    return valid.value;
  }

  async function handleSubmit(callback: () => void) {
    if (await validateAll()) {
      callback();
    }
  }

  return {
    validateAll,
    handleSubmit,
    reset,
    valid,
    invalid,
  };
}

export {
  _validationObserverKey,
  useValidationObserver,
  useValidationObserverProps,
};
