import type { LocaleIso } from "@/lib/helpers/locales";

export const zipcodeRegex = Object.freeze({
  "nl-NL": /^[1-9][0-9]{3}\s?(?!sa|sd|ss)[a-z]{2}$/i,
  "nl-NL_partial": /^[1-9][0-9]{0,3}\s?(?!sa|sd|ss)[a-z]{0,2}$/i,
  "nl-BE": /^[1-9][0-9]{3}$/,
  "nl-BE_partial": /^[1-9][0-9]{0,3}$/,
  "fr-BE": /^[1-9][0-9]{3}$/,
  "fr-BE_partial": /^[1-9][0-9]{0,3}$/,
  "fr-FR": /^[1-9][0-9]{4}$/,
  "fr-FR_partial": /^[1-9][0-9]{0,4}$/,
} satisfies {
  [_key in LocaleIso | `${LocaleIso}_partial`]: RegExp;
});
