import { computed, reactive, toRef } from "vue";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type { UseCheckboxRadioEmit } from "@/lib/components/logic/atoms/useCheckboxRadio";
import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  primitiveOrArrayValue,
  size,
} from "@/lib/components/logic/atoms/input/props";
import * as useCheckboxRadio from "@/lib/components/logic/atoms/useCheckboxRadio";
import { useModel } from "@/lib/composables";
import {
  emitsDefinition,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import {
  type OptionValue,
  useOptionsStore,
  useOptionsStoreScoped,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

const scoped = propsDefinition({
  ...useOptionsStoreScoped,
  size,
  modelValue: primitiveOrArrayValue,
  multiple: { type: Boolean, default: false },
  imageContain: { type: Boolean, default: false },
  inline: { type: Boolean, default: false },
  outlined: { type: Boolean, default: false },
});

const props = scoped;

const emits = emitsDefinition(["update:modelValue", ...useCheckboxRadio.emits]);

type UseCheckboxRadioGroupProps = DefineProps<typeof props>;
type UseCheckboxRadioGroupEmit = UseCheckboxRadioEmit &
  ((event: "update:modelValue", value: PrimitiveOrArrayValue) => void);

function use(
  props: UseCheckboxRadioGroupProps,
  emit: UseCheckboxRadioGroupEmit,
) {
  const modelValue = useModel("modelValue", props, emit, { local: true });

  const optionsStore = useOptionsStore(modelValue, props);

  const items = computed(() => {
    return optionsStore.items.value.map((item) => ({
      key: item.id,
      props: reactive({
        size: props.size,
        outlined: props.outlined,
        imageContain: props.imageContain,
        ...item,
      }),
      on: {
        ...reEmit(emit, useCheckboxRadio.emits),
        "update:modelValue": (
          modelValue: boolean,
          value: OptionValue | OptionValue[] = item.value,
        ) => optionsStore.setChecked(modelValue, value),
      },
    }));
  });

  return {
    optionsStore,
    items,
    inline: toRef(() => props.inline),
    outlined: toRef(() => props.outlined),
  };
}

export type { UseCheckboxRadioGroupEmit, UseCheckboxRadioGroupProps };
export { emits, props, scoped, use };
