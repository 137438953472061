<template>
  <SAlert
    v-if="isVisible"
    class="s-form-cross-sell-alert"
    color="primary"
    :prefix-icon="faInfoCircle"
  >
    {{ $t("application.crossSell", { productName: formStore.product?.name }) }}
    <br />
    <SButton
      action-type="quaternary"
      :href="href"
      size="inline"
      :suffix-icon="faExternalLink"
      target="_blank"
      >{{ $t("actions.openApplication") }}
    </SButton>
  </SAlert>
</template>

<script setup lang="ts">
import { faExternalLink, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { applyArgusUuidToUrl } from "@solvari/common-fe/argus/thin-client";
import { computed } from "vue";

import { SAlert, SButton } from "@solvari/common-fe";

import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";

const formStore = useFormStore();
const applicationStore = useApplicationStore();

const isVisible = computed(() => formStore.isCrossSellForm);

const href = computed(() => {
  if (!applicationStore.submitResult?.redirectUrl) {
    return;
  }
  return applyArgusUuidToUrl(applicationStore.submitResult.redirectUrl);
});
</script>
