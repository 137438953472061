import type { PartialDeep } from "type-fest";

import {
  getDotEnv,
  getEnv as getEnvCommon,
  initEnv as initEnvCommon,
} from "@solvari/common-fe/helpers";

const env = {
  config: {
    envFE: import.meta.env.MODE as "development" | "production" | "staging",
  },
  network: {
    api: {
      base: getDotEnv("VITE_URL_API", import.meta.env.VITE_URL_API),
    },
    argus: {
      base: getDotEnv("VITE_URL_ARGUS_API", import.meta.env.VITE_URL_ARGUS_API),
    },
    cdn: {
      morpheus: getDotEnv(
        "VITE_URL_CDN_MORPHEUS",
        import.meta.env.VITE_URL_CDN_MORPHEUS,
      ),
    },
  },
  integrations: {
    sentry: import.meta.env.VITE_SENTRY_DSN as string,
    pusher: {
      appId: getDotEnv(
        "VITE_PUSHER_APP_ID",
        import.meta.env.VITE_PUSHER_APP_ID,
      ),
      key: getDotEnv(
        "VITE_PUSHER_APP_KEY",
        import.meta.env.VITE_PUSHER_APP_KEY,
      ),
      cluster: getDotEnv(
        "VITE_PUSHER_APP_CLUSTER",
        import.meta.env.VITE_PUSHER_APP_CLUSTER,
      ),
    },
  },
};

const getEnv = getEnvCommon<typeof env>;
const initEnv = () => initEnvCommon(env, window.solvariEnv ?? {});

export { getEnv, initEnv };
export type SolvariEnv = PartialDeep<ReturnType<typeof getEnv>>;
