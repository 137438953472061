import { diff } from "radash";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";

import type { UseInputFieldAtomEmit } from "./useInputFieldAtom";
import type { UseInputIconAtomEmit } from "./useInputIconAtom";

import * as useInputFieldAtom from "./useInputFieldAtom";
import * as useInputIconAtom from "./useInputIconAtom";
import * as useInputWrapperAtom from "./useInputWrapperAtom";

const scoped = propsDefinition({
  ...useInputFieldAtom.scoped,
  ...useInputWrapperAtom.scoped,
  ...useInputIconAtom.scoped,
});

const props = propsDefinition({
  ...useInputIconAtom.props,
  ...useInputFieldAtom.props,
  ...useInputWrapperAtom.props,
});

const emits = emitsDefinition([
  ...useInputIconAtom.emits,
  ...useInputFieldAtom.emits,
  "click",
  "mousedown",
  "mouseup",
]);

type UseInputAtomProps = DefineProps<typeof props>;
type UseInputAtomEmit = UseInputFieldAtomEmit &
  UseInputIconAtomEmit &
  ((event: "click" | "mousedown" | "mouseup") => void);

function use(props: UseInputAtomProps, emit: UseInputAtomEmit) {
  const inputWrapperAtom = {
    props: pickProps(props, useInputWrapperAtom.props),
    on: reEmit(emit, ["click", "mousedown", "mouseup"]),
  };

  const inputFieldAtom = {
    props: pickProps(props, useInputFieldAtom.props),
    on: reEmit(emit, diff(useInputFieldAtom.emits, ["click"])),
  };

  const inputIconAtom = {
    props: pickProps(props, useInputIconAtom.props),
    on: reEmit(emit, useInputIconAtom.emits),
  };

  return { inputWrapperAtom, inputFieldAtom, inputIconAtom };
}

export { emits, props, scoped, use };
export type { UseInputAtomEmit, UseInputAtomProps };
