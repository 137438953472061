import type { Ref } from "vue";

import { toCamel } from "ts-case-convert";

import type { FormComponentAll } from "@/helpers/types";

type PreselectConditionAttribute = "constructionYear";

type Condition =
  | "EQUAL"
  | "GREATER_THAN_OR_EQUAL"
  | "GREATER_THAN"
  | "LESS_THAN_OR_EQUAL"
  | "LESS_THAN";

function checkDynamicCondition(
  value1: number | string | null,
  condition: Condition,
  value2: number | string,
) {
  if (value1 === null) {
    return false;
  }

  return {
    equal: () => value1 === value2,
    lessThan: () => value1 < value2,
    greaterThan: () => value1 > value2,
    lessThanOrEqual: () => value1 <= value2,
    greaterThanOrEqual: () => value1 >= value2,
  }[toCamel(condition.toLowerCase() as Lowercase<Condition>)]();
}

function getDefaultAdditional(
  question: FormComponentAll,
  preselectState?: Record<PreselectConditionAttribute, number | null>,
) {
  if (!("answers" in question)) {
    return null;
  }

  const defaultAnswerByCondition = question.answers.find(
    ({ preselectConditions }) => {
      if (!preselectConditions || !preselectState) {
        return false;
      }
      return preselectConditions.every(({ attribute, condition, value }) =>
        checkDynamicCondition(preselectState[attribute], condition, value),
      );
    },
  );

  if (defaultAnswerByCondition) {
    return defaultAnswerByCondition;
  }

  return question.answers.find(({ isDefault }) => isDefault) ?? null;
}

function setDefaultAdditional(
  question: FormComponentAll,
  modelValue: Ref<unknown>,
  preselectState?: Record<PreselectConditionAttribute, number | null>,
) {
  if (modelValue.value) {
    return;
  }

  const defaultAnswer = getDefaultAdditional(question, preselectState);
  if (!defaultAnswer) {
    return;
  }

  modelValue.value = ["FormCheckbox", "FormCheckboxImage"].includes(
    question.type,
  )
    ? [defaultAnswer.value]
    : defaultAnswer.value;
}

export { setDefaultAdditional };
export type { Condition, PreselectConditionAttribute };
