<template>
  <SAlert
    v-if="isVisible"
    class="s-form-recent-application"
    color="primary"
    :prefix-icon="faInfoCircle"
  >
    {{ $t("application.hasRecentApplication") }}<br />
    <SButton
      action-type="quaternary"
      :href="href"
      size="inline"
      :suffix-icon="faExternalLink"
      target="_blank"
      >{{ $t("actions.openApplication") }}
    </SButton>
  </SAlert>
</template>

<script setup lang="ts">
import { faExternalLink, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { applyArgusUuidToUrl } from "@solvari/common-fe/argus/thin-client";
import { computed } from "vue";

import { SAlert, SButton } from "@solvari/common-fe";

import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";

const applicationStore = useApplicationStore();
const formStore = useFormStore();

const isVisible = computed(
  () => !!applicationStore.mostRecentApplication && !formStore.isCrossSellForm,
);

const href = computed(() => {
  if (!applicationStore.mostRecentApplication?.redirectUrl) {
    return;
  }
  return applyArgusUuidToUrl(
    applicationStore.mostRecentApplication.redirectUrl,
  );
});
</script>
