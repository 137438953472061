<template>
  <input
    v-bind="input.props"
    :ref="input.ref"
    class="s-input-field-atom"
    :class="{
      [`s-input-field-atom--size-${size}`]: true,
      's-input-field-atom--prefix-icon': hasPrefixIcon,
      's-input-field-atom--suffix-icon': hasSuffixIcon,
    }"
    v-on="input.on"
  />
</template>

<script setup lang="ts">
import type { UseInputFieldAtomEmit } from "@/lib/components/logic/atoms/input/useInputFieldAtom";

import * as useInputFieldAtom from "@/lib/components/logic/atoms/input/useInputFieldAtom";

const props = defineProps(useInputFieldAtom.props);
const emit = defineEmits<UseInputFieldAtomEmit>();
const { input, size, hasPrefixIcon, hasSuffixIcon } = useInputFieldAtom.use(
  props,
  emit,
);
</script>

<style lang="postcss">
.s-input-field-atom {
  @apply w-full flex-grow rounded bg-transparent text-gray-800;

  &--size {
    &-lg {
      @apply px-6 text-lg leading-none;
      height: calc(theme("spacing.14") - 2px);
    }

    &-md {
      @apply px-5 text-base leading-none;
      height: calc(theme("spacing.12") - 2px);
    }

    &-sm {
      @apply px-4 text-base leading-none;
      height: calc(theme("spacing.10") - 2px);
    }
  }

  &--prefix-icon {
    @apply pl-0;
  }

  &--suffix-icon {
    @apply pr-0;
  }

  &:focus {
    @apply outline-none;
  }

  &::placeholder {
    @apply text-gray-400 transition-text;
  }

  &:disabled {
    @apply text-gray-600;

    &::placeholder {
      @apply text-gray-300;
    }
  }
}
</style>
