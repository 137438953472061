<template>
  <component
    :is="tag"
    v-bind="{ ...wrapper.props, ...$attrs }"
    class="s-input-wrapper-atom"
    :class="{
      [`s-input-wrapper-atom--size-${size}`]: true,
      's-input-wrapper-atom--enabled': !disabled,
      's-input-wrapper-atom--disabled': disabled,
      's-input-wrapper-atom--valid': valid,
      [`s-input-wrapper-atom--${color}`]: !!color,
    }"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import * as useInputWrapperAtom from "@/lib/components/logic/atoms/input/useInputWrapperAtom";
const props = defineProps(useInputWrapperAtom.props);
const { wrapper, tag, disabled, valid, color, size } =
  useInputWrapperAtom.use(props);
</script>

<style lang="postcss">
.s-input-wrapper-atom {
  @apply flex w-full items-stretch rounded border border-gray-200 bg-white text-left transition-colors;

  &--size {
    &-lg {
      @apply min-h-14;
    }

    &-md {
      @apply min-h-12;
    }

    &-sm {
      @apply min-h-10;
    }
  }

  &:hover {
    @apply bg-gray-50;
  }

  &:focus-within {
    @apply border-primary-500 outline-none;
  }

  &--disabled,
  &--disabled:focus-within,
  &--disabled:hover {
    @apply cursor-default border-gray-200 bg-gray-100 text-gray-600;
  }

  &--valid {
    @apply border-success-500;
  }

  &--warning {
    @apply border-warning-500;
  }

  &--danger {
    @apply border-danger-500;
  }
}
</style>
