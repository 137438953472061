<template>
  <li
    :ref="option.ref"
    class="s-option"
    :class="{
      's-option--disabled': disabled,
      's-option--checked': modelValue,
      [`s-option--size-${size}`]: true,
      's-option--active': isActive,
    }"
    v-bind="option.props"
    v-on="option.on"
  >
    <SCheckboxRadioInput v-if="input.if.value" v-bind="input.props" />
    <span class="s-option__label" v-html="label" />
    <slot name="suffix" />
  </li>
</template>

<script setup lang="ts">
import type { UseOptionEmit } from "@/lib/components/logic/atoms/useOption";

import SCheckboxRadioInput from "@/lib/components/atoms/checkbox-radio/SCheckboxRadioInput.vue";
import * as useOption from "@/lib/components/logic/atoms/useOption";

const props = defineProps(useOption.props);
const emit = defineEmits<UseOptionEmit>();

const { input, option, label, disabled, modelValue, size, isActive } =
  useOption.use(props, emit);
</script>

<style lang="postcss">
.s-option {
  @apply relative flex cursor-pointer select-none items-center gap-3 bg-white font-normal transition-colors;

  &:hover {
    @apply bg-gray-100;
  }

  &__label {
    @apply py-1 text-gray-900;
  }

  &::before {
    content: "";
    @apply absolute left-0 top-0 h-full w-0.5 bg-transparent transition-bg;
  }

  &--checked::before {
    @apply bg-primary-700;
  }

  &--checked {
    @apply font-medium;
  }

  &--active {
    @apply bg-primary-100;
  }

  &--size {
    &-lg {
      @apply min-h-10 px-6 text-lg leading-tight;
    }

    &-md {
      @apply min-h-9 px-5 text-base leading-tight;
    }

    &-sm {
      @apply min-h-8 px-4 text-sm leading-tight;
    }
  }

  &--disabled {
    @apply cursor-default bg-gray-50;

    &:hover {
      @apply bg-gray-50;
    }

    .s-option__label {
      @apply text-gray-600;
    }
  }
}
</style>
