import type { PropType } from "vue";

import { computed, reactive, ref, toRef } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile } from "@/lib/composables/useFileUpload";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";

const scoped = propsDefinition({
  removable: { type: Boolean, default: true },
});

const props = propsDefinition({
  ...scoped,
  files: { type: Array as PropType<readonly SFile[]>, default: () => [] },
});

const emits = emitsDefinition(["removedFile"]);

type UsePreviewProps = DefineProps<typeof props>;
type UsePreviewEmit = (event: "removedFile", removedUuid: string) => void;

function use(props: UsePreviewProps, emit: UsePreviewEmit) {
  const files = computed(() =>
    props.files.map((file) => ({ ...file, alt: file.alt ?? undefined })),
  );

  const removable = toRef(() => props.removable);
  function remove(removedUuid: string) {
    if (removable.value) {
      emit("removedFile", removedUuid);
    }
  }

  const lightboxImage = ref<string>();

  function openImage(uuid?: string) {
    lightboxImage.value = uuid;
  }

  const images = computed(() => {
    return files.value.filter(({ mimeType }) => mimeType.includes("image"));
  });

  const lightboxAtom = {
    props: reactive({ images, modelValue: lightboxImage }),
    on: { "update:modelValue": openImage },
  };

  return { files, removable, remove, openImage, lightboxAtom };
}

export type { UsePreviewEmit, UsePreviewProps };
export { emits, props, scoped, use };
