import type { PropType } from "vue";

import { computed, toRef } from "vue";

import type { Progress } from "@/lib/components/atoms/progress/progress.types";
import type { DefineProps } from "@/lib/composables/componentComposable";

const props = {
  modelValue: {
    type: [Number, Array] as PropType<Progress[] | number>,
    default: () => [],
  },
  color: {
    type: String,
    default: "accent-500",
  },
  max: {
    type: Number,
    default: 100,
  },
  width: {
    type: Number,
    default: 5,
  },
} as const;

type UseProgressProps = DefineProps<typeof props>;

function use(props: UseProgressProps) {
  const progresses = computed<Progress[]>(() => {
    if (typeof props.modelValue === "number") {
      return [{ value: props.modelValue, color: props.color }];
    }
    return props.modelValue;
  });

  return {
    progresses,
    max: toRef(() => props.max),
    width: toRef(() => `${props.width}px`),
  };
}

export type { UseProgressProps };
export { props, use };
