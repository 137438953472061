<template>
  <STextarea
    v-model="model"
    class="form-question"
    :maxlength="950"
    name="description"
    :placeholder="props.question.placeholder"
    :required="localeIso === 'fr-FR'"
    show-required-type="required"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import { useI18n } from "@solvari/common-fe/composables";
import { type LocaleIso, reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "@vueuse/core";
defineOptions({ inheritAttrs: false });

import { STextarea } from "@solvari/common-fe";

import type { FormComponentRequired } from "@/helpers/types";

import { useLeadStore } from "@/plugins/store/lead";

const props = defineProps<{ question: FormComponentRequired }>();
const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const { locale } = useI18n();
const localeIso = toRef(() => locale.value as LocaleIso);

const leadStore = useLeadStore();
const model = toRef(leadStore, "description");
</script>
