<template>
  <SAddress
    v-model:city="city"
    v-model:house-number="houseNumber"
    v-model:locale="locale"
    v-model:street="street"
    v-model:zipcode="zipcode"
    :available-locales="availableLocales"
    class="form-question"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { storeToRefs } from "pinia";
import { computed, toRef } from "vue";

import { SAddress } from "@solvari/common-fe";

import { useFormStore } from "@/plugins/store/form";
import { useLeadStore } from "@/plugins/store/lead";

defineOptions({ inheritAttrs: false });

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const formStore = useFormStore();
const availableLocales = toRef(() => formStore.availableLocales);

const locale = computed({
  get: () => formStore.locale!,
  set: (value) => (formStore.formGetProps.locale = value),
});

const leadStore = useLeadStore();
const { zipcode, houseNumber, city, street } = storeToRefs(leadStore);
</script>
