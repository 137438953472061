import ky from "ky";
import { shake } from "radash";

import type { LocaleIso } from "@/lib/helpers";

import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { getEnv } from "@/lib/helpers/solvariEnv";
import { memoize } from "@/lib/helpers/utils/memoize";
import { withKyErrors } from "@/lib/integrations/ky";

const getZipcodeCitySuggestions = memoize(
  async ({
    zipcode,
    city,
    locale,
  }: {
    city: string | null;
    locale: LocaleIso;
    zipcode: string | null;
  }): Promise<{ city: string; zipcode: string }[]> => {
    // This should match BE validation
    if (
      (zipcode && !zipcodeRegex[`${locale}_partial`].test(zipcode)) ||
      (!zipcode && !city?.trim())
    ) {
      return [];
    }

    const response = await ky
      .get(`${getEnv().network.api.base}/system/v3/geo/suggest/city/be`, {
        searchParams: shake({ zipcode, city, locale }),
      })
      .json<{ data: { city: string; zipcode: string }[] }>();
    return response.data;
  },
);

const getCitySuggestions = memoize(
  async ({
    locale,
    city,
  }: {
    city: string | null;
    locale: LocaleIso;
  }): Promise<string[]> => {
    // This should match BE validation
    if (!city?.trim()) {
      return [];
    }

    const response = await ky
      .get(`${getEnv().network.api.base}/system/v3/geo/suggest/city`, {
        searchParams: { locale, city },
      })
      .json<{ data: string[] }>();
    return response.data;
  },
);

async function getStreetBeSuggestions({
  locale,
  zipcode,
  street,
}: {
  locale: LocaleIso;
  street: string | null;
  zipcode: string | null;
}): Promise<string[]> {
  // This should match BE validation
  if (!street?.trim() || !(zipcode && zipcodeRegex[locale].test(zipcode))) {
    return [];
  }

  const response = await ky
    .get(`${getEnv().network.api.base}/system/v3/geo/suggest/street/be`, {
      searchParams: { locale, zipcode, street },
    })
    .json<{ data: string[] }>();

  return response.data;
}

async function getStreetNlSuggestions({
  locale,
  city,
  street,
}: {
  city: string | null;
  locale: LocaleIso;
  street: string | null;
}): Promise<string[]> {
  // This should match BE validation
  if (!street?.trim()) {
    return [];
  }

  const response = await ky
    .get(`${getEnv().network.api.base}/system/v3/geo/suggest/street`, {
      searchParams: shake({ locale, city, street }),
    })
    .json<{ data: string[] }>();

  return response.data;
}

const getStreetSuggestions = memoize(
  async (
    params:
      | {
          locale: "fr-BE" | "nl-BE";
          street: string | null;
          zipcode: string | null;
        }
      | { city: string | null; locale: "nl-NL"; street: string | null },
  ) => {
    if (params.locale === "nl-NL") {
      return getStreetNlSuggestions(params);
    }
    return getStreetBeSuggestions(params);
  },
);

const getHouseNumberSuggestions = memoize(
  async ({
    locale,
    zipcode,
    houseNumber,
  }: {
    houseNumber: string | null;
    locale: LocaleIso;
    zipcode: string | null;
  }): Promise<string[]> => {
    // This should match BE validation
    if (
      !(zipcode && zipcodeRegex[locale].test(zipcode)) ||
      !houseNumber?.trim()
    ) {
      return [];
    }

    const response = await ky
      .get(`${getEnv().network.api.base}/system/v3/geo/suggest/housenumbers`, {
        searchParams: { locale, zipcode, housenumber: houseNumber },
      })
      .json<{ data: string[] }>();
    return response.data;
  },
);

type AutocompleteResult = {
  city: string;
  street: string;
  zipcode: string;
};

const autocompleteNlNlAddress = withKyErrors(
  memoize(async ({ zipcode }: { zipcode: string }) => {
    // This should match BE validation
    if (!zipcodeRegex["nl-NL"].test(zipcode)) {
      return null;
    }

    const response = await ky
      .get(`${getEnv().network.api.base}/system/v3/geo/autocomplete`, {
        searchParams: { zipcode },
      })
      .json<AutocompleteResult | Record<string, never>>();
    if (!Object.keys(response).length) {
      return null;
    }
    return response as AutocompleteResult;
  }),
);

export {
  autocompleteNlNlAddress,
  getCitySuggestions,
  getHouseNumberSuggestions,
  getStreetSuggestions,
  getZipcodeCitySuggestions,
};
