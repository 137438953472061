import type { Simplify, TupleToUnion } from "type-fest";
import type { UnwrapNestedRefs } from "vue";

import { omit, pick } from "radash";
import { isReactive, reactive, readonly, toRef, toRefs } from "vue";

import type { MergeObjects } from "@/lib/helpers/types";

function reactivePick<
  ObjectType extends Record<PropertyKey, unknown>,
  Keys extends (keyof ObjectType)[],
>(object: ObjectType, keys: Keys) {
  return reactive(pick(toRefs(object), keys)) as Simplify<
    Pick<ObjectType, TupleToUnion<Keys>>
  >;
}

function reactiveOmit<
  ObjectType extends Record<PropertyKey, unknown>,
  Keys extends (keyof ObjectType)[],
>(object: ObjectType, keys: Keys) {
  return reactive(omit(toRefs(object), keys)) as Simplify<
    Omit<ObjectType, TupleToUnion<Keys>>
  >;
}

function mergeReactive<Objects extends Record<PropertyKey, unknown>[]>(
  ...objects: Objects
) {
  const merged = objects.reduceRight((merged, object) => {
    const unwrapped = isReactive(object) ? object : reactive(object);
    Object.keys(unwrapped)
      .filter((key) => !(key in merged))
      .forEach((key) => (merged[key] = toRef(() => unwrapped[key])));
    return merged;
  }, {});

  return readonly(reactive(merged)) as Simplify<
    UnwrapNestedRefs<Readonly<MergeObjects<Objects>>>
  >;
}

type ListenerCallback = (event: never) => Promise<void> | void;

type ListenerObject = Record<string, ListenerCallback | ListenerCallback[]>;
function mergeListeners(...listenerObjects: ListenerObject[]) {
  return listenerObjects.reduce(
    (mergedListeners: ListenerObject, listeners: ListenerObject) => {
      Object.entries(listeners).forEach(([listener, callback]) => {
        const existingListeners = mergedListeners[listener];
        if (existingListeners) {
          mergedListeners[listener] = [existingListeners, callback].flat();
        } else {
          mergedListeners[listener] = callback;
        }
      });

      return mergedListeners;
    },
    {},
  );
}

export { mergeListeners, mergeReactive, reactiveOmit, reactivePick };
