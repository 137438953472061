import type { PropType } from "vue";

import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { isNumber } from "radash";
import { computed, ref, toRef } from "vue";

import type { Size } from "@/lib/components/types";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SIconDefinition } from "@/lib/icons/fontAwesome.types";

import {
  emitsDefinition,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useModel } from "@/lib/composables/useModel";

const scoped = propsDefinition({
  modelValue: {
    type: Number as PropType<number | null | undefined>,
    default: null,
  },
  length: { type: Number, default: 5 },
  size: { type: String as PropType<Size>, default: "md" },
  icon: { type: Object as PropType<SIconDefinition>, default: () => faStar },
  showNumberScore: { type: Boolean, default: true },
  readonly: { type: Boolean, default: true },
});

const props = scoped;

const emits = emitsDefinition(["update:modelValue"]);

type UseReviewScoreAtomProps = DefineProps<typeof props>;
type UseReviewScoreAtomEmit = (
  event: "update:modelValue",
  value: number | null | undefined,
) => void;

function use(props: UseReviewScoreAtomProps, emit: UseReviewScoreAtomEmit) {
  const modelValue = useModel("modelValue", props, emit, { local: true });

  const filledStars = computed(() => Math.floor(modelValue.value ?? 0));
  const starFraction = computed(
    () => `${((modelValue.value ?? 0) % 1) * 100}%`,
  );

  const hoverStars = ref<number | null>(null);

  const scoreDisplay = computed<string>(() => {
    if (isNumber(hoverStars.value)) {
      const fractionDigits = Math.ceil(hoverStars.value % 1);

      return hoverStars.value.toFixed(fractionDigits);
    }

    return modelValue.value?.toString() ?? "0";
  });

  function setTemporaryValue(index: number) {
    hoverStars.value = index + 1;
  }

  function unsetTemporaryValue() {
    hoverStars.value = null;
  }

  function setValue(index: number) {
    unsetTemporaryValue();
    modelValue.value = index + 1;
  }

  const element = computed(() => (props.readonly ? "div" : "button"));

  const stars = computed(() => {
    const starList = [];

    const visualValue = isNumber(hoverStars.value)
      ? hoverStars.value
      : filledStars.value;

    for (let index = 0; index < props.length; index++) {
      let on = {};
      let fillState = "empty";

      if (index < visualValue) {
        fillState = "filled";
      } else if (
        index === visualValue &&
        starFraction.value !== "0%" &&
        !isNumber(hoverStars.value)
      ) {
        fillState = "partial";
      }

      if (!props.readonly) {
        on = {
          click: () => setValue(index),
          mouseenter: () => setTemporaryValue(index),
          mouseleave: unsetTemporaryValue,
        };
      }

      starList.push({ fillState, on });
    }

    return starList;
  });
  return {
    scoreDisplay,
    filledStars,
    starFraction,
    hoverStars,
    stars,
    element,
    size: toRef(() => props.size),
    icon: toRef(() => props.icon),
    value: toRef(() => props.modelValue),
    readonly: toRef(() => props.readonly),
    showNumberScore: toRef(() => props.showNumberScore),
    length: toRef(() => props.length),
  };
}

export type { UseReviewScoreAtomEmit, UseReviewScoreAtomProps };
export { emits, props, scoped, use };
